@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

.cartscreen {
  font-family: "Poppins", sans-serif;
}
.wishlist-cardd{
  width: 100%;
  display: flex;
}
@media only screen and (max-width: 600px) {
  .card{
  width: 50%;
  height: inherit;
  min-height: 50%;
  display: inline-block;
  vertical-align: top;
  }
  .wishlist-cardd {
    width: auto;
    display: block;
}
}