.founder-container-box{
  text-align: center;
    padding: 50px 0 0;
}
.founder-container-box img{
  width: 40%;
  border: 5px solid #c69736;
}
.company-side img{
  float: right;
}
.founder-title{
  margin: 8px;
  font-size: 25px;
  font-weight: bold;
  color: #c69736;
}
.founder-designation{
margin: 0;
font-family: 'Poppins';
font-size: 15px;
font-weight: bold;
color: #000;
}
.aboutus-container {
    background-image: url("../../assets/images/glitter7.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 140px;
    display: flex;
    flex-direction: column;
  }

  .aboutus-head h1 {
    font-family: "Gill Sans W04 Roman", Calibri, sans-serif;
    font-size: 4rem;
    padding: 0px 25%;
    font-weight: bold;
    line-height: 1em;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    letter-spacing: 0.06667em;
    text-transform: uppercase;
    color: #000;
  }
  
  .aboutus-head {
    padding: 1.25em 0;
    background: #fff;
    mix-blend-mode: lighten;
  }
  
  .aboutus-head::after {
    display: table;
    clear: both;
    content: "";
  }
  
  .aboutus-container-box {
    display: flex;
    flex-direction: column;
    padding: 80px;
    padding-top: 0px;
    background-color: white;
  }
  
  .aboutus-logo hr,
  .aboutus-logo img {
    display: inline;
  }
  
  .aboutus-logo {
    display: flex;
    align-items: center;
    padding: 0px 7%;
    background-color: white;
    padding-top: 50px;
  }
  
  .aboutus-logo hr {
    width: 100%;
    height: 1px;
    border: none;
    border-top: 1px solid rgb(167, 167, 167);
    justify-content: center;
  }
  
  .aboutus-logo img {
    width: 160px;
    height: 75px;
    margin: 0px 20px;
  }
  
  .aboutus-logo hr,
  .aboutus-logo img {
    display: inline;
  }
  
  .aboutus-logos {
    display: flex;
    align-items: center;
    padding: 0px ;
    background-color: white;
    
  }
  .aboutus-logos p{
      flex-grow: 3;
      width: 100%;
      font-size: 1.1em;
      font-weight: 1000;
      color: #a87e3b;
      text-align: center;
      letter-spacing: 5px;
  }
  
  .aboutus-logos hr {
    width: 100%;
    height: 1px;
    border: none;
    border-top: 1px solid rgb(167, 167, 167);
    justify-content: center;
  }

  .about-para {
    color: #555;
    padding: 20px 5%;
    text-align: center;
    font-weight: 800;
    font-size: 1.1em;
    line-height: 30px;
  }
  
  .aboutus-form form {
    display: flex;
    flex-direction: column;
    padding: 30px 25%;
  }
  
  .aboutus-form form input {
    padding: 12px 16px;
  }
  
  .aboutus-form form label {
    font-size: 0.8em;
    margin: 10px 0px;
  }
  
  .sub-button {
    margin: 10px 20%;
    color: white;
    background: #6d4d15;
    border: none;
    font-size: 0.8em;
  }
  
  .about-us-decor {
    display: flex;
    padding-top: 60px;
    justify-content: center;
  }
  
  .about-us-decor div {
    width: 100%;
  }
  
  .about-us-decor div img {
    height: 400px;
    width: 400px;
    margin: 0px;
    border: 5px solid #c69736;
  }
  
  .about-us-decor div h5 {
    text-transform: uppercase;
    color: #c69736;
    font-size: 30px;
    letter-spacing: 0;
    text-align: left;
    margin: 0;
  }
  
  .about-us-decor div p {
    color: #000;
    text-align: justify;
    font-family: 'Poppins';
    font-weight: 1000;
    letter-spacing: 0.5px;
    line-height: 30px;
  }
  
.term-condition-cont table{
text-align: center;
align-items: flex-start;
}

.term-condition-cont table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .term-condition-cont table th, .term-condition-cont table td {
    text-align: left;
    padding: 8px;
  }
  
  .term-condition-cont table tr:nth-child(odd) {background-color: #f2f2f2;}

  .term-cond b{
    font-family: 'Merriweather', serif;
    font-size: .8125em;
    font-weight: 600;
    line-height: 2.5;
    letter-spacing: .04em;
    color: #555;
  }
    .aboutus-container-box{
    background-color: white;
  }
  .term-ul li{
    padding-bottom: 15px;
  }
  .term-ol li{
    padding-bottom: 15px;
  }
  @media only screen and (max-width: 900px) {
    .about-us-decor div img {
      height: 250px;
      width: 100%;
      margin: 20px;
    }
    .founder-container-box{
      padding: 5px 0 0;
    }
    .founder-container-box img {
      width: 90%;
    }
    .about-us-decor {
      flex-direction: column;
      padding-top: 25px;
    }
    .aboutus-logo {
      padding: 20px;
    }
    .aboutus-head h1 {
      padding: 10px;
      font-size: 2.5em;
    }
    .about-para {
      padding: 20px;
    }
    .aboutus-form form {
      padding: 30px;
    }
    .abooutus-images div:nth-child(2){
  order: 1;
    }
    .abooutus-images div:nth-child(1){
  order: 2;
  
    }
  }
  
  @media only screen and (max-width: 600px) {
      .aboutus-container {
      padding: 20px;
      }
  .aboutus-container-box {
  padding: 10px;
  }
  .about-us-decor div p{
    padding: 0 10px;
  }
  .about-us-decor div h5{
    padding: 0 10px;
  }
  .about-us-decor div img {
      height: 250px;
      width: 90%;
      margin: 10px;
    }
  }

.text_rtl {
    direction: rtl;
}

sub-li {
    content: counters(item, ".") " ";
    counter-increment: item
}

.contact_us {
    color: #c69736;
    font-size: 20px;
    cursor: pointer;
}