.cancel_ctr {
    margin: 10px 0px 20px 0px;
    display: flex;
    padding: 15px;
    justify-content: center;
}

.oopwa-ctr {
    margin: 10px 0px 10px 0px;
    display: flex;
    padding: 15px;
    justify-content: center;
}

.cancel-btn-oopwa {
    border-radius: 5px;
}

.failedCtr {
    color: white;
    min-height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.failedBtn {
    text-decoration: none;
    cursor: pointer;
    font-size: 15px;
    color: #b28e35;
}

.failedBtn:hover {
    color: antiquewhite;
    text-decoration: underline;
    cursor: pointer;
    font-size: 15.5px;
}