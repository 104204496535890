.payment-page {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  border-bottom: 1px solid #c69736;
  height: auto;
}

.payment-left-info {
  height: auto;
  width: 60%;
}

@media only screen and (max-width: 600px) {
  .payment-left-info{
    width: 100%;
    order: 2;
  }
}

.payment-email{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}