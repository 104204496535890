.shipping-page {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  border-bottom: 1px solid #c69736;
}

.shipping-top-info {
  width: 100%;
  height: 6vh;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #c69736;
  color: #c69736;
}

.shipping-top-info h3 {
  font-weight: 400;
  margin: 0.4rem;
  padding-left: 2rem;
}

.shipping-left-info {
  height: auto;

  width: 60%;
}

.shipping-right-info {
  height: auto;
  width: 40%;
}

.right-info-2 {
  position: sticky;
  height: 100vh;
  top: 0;
  width: 40%;
  background: rgb(56, 56, 56);
}

.first-right-div {
  display: flex;
  justify-content: space-between;
}

.inner-section-info div {
  border-bottom: 1px solid gray;
}

.first-right-div img {
  height: 100px;
  width: 100px;
}

.second-right-div {
  padding: 2rem;
}

.second-right-div input,
button {
  padding: 1rem;
}

.second-right-div input {
  width: 60%;
}

.second-right-div button {
  width: 25%;
  margin-left: 1rem;
  color: #fff;
  background: gray;
}

.inner-section-info {
  padding: 1rem;
  margin: 3rem;
}

.top-logo {
  width: 100%;
  height: auto;
}

.top-logo img {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 250px;
  height: 200px;
  margin-top: 2rem;
}

.bottom-section {
  display: flex;
  flex-direction: column;
  margin: 7rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.bottom-section section {
  background: transparent;
    border: 1px solid #c69736;
    color: #fff;
    padding: 1rem;
    margin-bottom: 20px;
    gap: 15px;
    border-radius: 0;
}

.shipping-email{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inner-section {
  display: flex;
  justify-content: space-between;
}

.last-section {
  margin: 0 7rem;
  margin-bottom: 0px;
}

.icon {
  display: block;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  margin: 0 auto;
}

.icon:before,
.icon:after {
  content: "";
  position: absolute;
}

.dropp {
  width: 100%;
  box-shadow: 0 1px 3px #0a0a0a;
}

.dropp-header {
  background: #242424;
  color: #fff;
  border-bottom: 2px solid #c69736;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  box-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  -ms-align-items: stretch;
  -o-align-items: stretch;
  align-items: stretch;
  -ms-flex-align: stretch;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  box-direction: normal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  -o-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: start;
}

.dropp-header__title {
  display: block;
  padding: 0.8em 0.5em;
  -webkit-box-flex: 8;
  -moz-box-flex: 8;
  box-flex: 8;
  -webkit-flex: 8;
  -moz-flex: 8;
  -ms-flex: 8;
  flex: 8;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.dropp-header__btn {
  display: block;
  background: #1c1c1c;
  color: #fff;
  padding: 0.8em 0.5em;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.dropp-header__btn .icon {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.dropp-header__btn .icon:before,
.dropp-header__btn .icon:after {
  top: 30%;
  left: 25%;
  width: 50%;
  height: 15%;
  background: #c69736;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.dropp-header__btn .icon:after {
  top: 55%;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dropp-header__btn.js-open {
  background: #c69736;
}

.dropp-header__btn.js-open .icon {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.dropp-header__btn.js-open .icon:before,
.dropp-header__btn.js-open .icon:after {
  background: #fff;
}

.dropp-header__btn:focus {
  outline: none;
}

.dropp-body {
  overflow: hidden;
  width: 100%;
  max-height: 0;
  background: #292929;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.dropp-body.js-open {
  max-height: 20em;
}

.dropp-body label {
  display: block;
  font-size: 0.875em;
  color: #fff;
  text-decoration: none;
  padding: 1em 0.5em;
  font-weight: 400;
  box-shadow: 0 -1px 0 #171717, inset 0 1px 0 #313131;
  cursor: pointer;
}

.dropp-body label:first-child {
  box-shadow: none;
}

.dropp-body label:hover,
.dropp-body label.js-open {
  background: #c69736;
}

.dropp-body label > input {
  display: none;
}

.shipping-heading{
   color: #c69736;
    text-transform: uppercase;
    font-size: 16px;
}
@media only screen and (max-width: 600px) {
  .right-info-2{
    width: 100%;
    height: auto;
    order: 1;
    position: relative;
  }
  .shipping-top-info{
    height: auto;
  }
  .shipping-left-info{
    width: 100%;
    order: 2;
  }
  .bottom-section{
    margin: 0 20px;
  }
  .last-section{
    margin: 20px;
  }
  .last-section span{
    display: inline-block;
  }
}