.my-account {
  display: flex;
  flex-wrap: wrap;  
  border-bottom: 1px solid #c69736;
}

.two-buttons {

}
.two-buttons a {

}
.breadcrumb-css{
  color: #c69736;
  margin: 0;
  padding: 10px 40px;
  font-size: 15px;
  border-top: 1px solid #c69736;
  border-bottom: 1px solid #c69736;
}
.sidebar-left{
  width: 20%;
  display: inline-block;
  background-color: transparent;
    border-right: 1px solid #c69736;
}
.address-right{
  width: 77%;
  display: inline-block;
  padding: 50px 0;
  text-align: center;
}
.saved-address-div{
    border: 1px solid #c69736;
    color: white;
    margin: 0 90px;
    padding: 2rem;
    position: relative;
}
.heading-address{
  color: #fff;
  padding: 10px 0;
}
@media only screen and (max-width: 600px) {
  .address-right{
    width: auto;
  }
  .sidebar-left{
    width: 100%;
  }
  .saved-address-div{
    margin: 0 20px;
  }
}