.checkout-box {
  margin-top: 30px;
  background: #2b2b2b;
  width: 90%;
  color: white;
  border: 1px solid #d49b0b;
}
.checkout-box1 {
  padding: 0.5rem 1.5rem;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
}

.checkout-summary {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.checkout-summary-head {
  border-bottom: 1px solid white;
}

.checkout-summary-head h1 {
  font-weight: 500;
  margin: 0.8rem;
  margin-left: 0;
  text-transform: uppercase;
  color: #b98d32;
}

.checkout-box2 {
  padding: 1.5rem 1.5rem;
}
.shipping {
  border-bottom: 1px solid white;
}
.subtotal,
.shipping,
.ordertotal {
  display: flex;
}
.subtotal div:last-child,
.shipping div:last-child,
.ordertotal div:last-child {
  text-align: right;
}
.subtotal div,
.shipping div,
.ordertotal div {
  width: 100%;
  padding-bottom: 1.5rem;
}
.ordertotal div {
  font-size: 1.5em;
  font-weight: 500;
  padding: 10px 0;
}
.checkout-box3 {
  background: black;
  padding: 20px 50px;
  text-align: center;
  
}
.checkout-box3 button {
  padding: 1rem 2rem;
  border: 1px solid white;
  color: #000;
  background: #c69736;
  text-transform: uppercase;
  font-size: 18px;
  width: 100%;
  font-weight: 500;
}
