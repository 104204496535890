@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

.navbar {
  font-family: "Poppins", sans-serif;
  background-color: #000;
  border-top: 2px solid rgb(255, 255, 255);
}
.navbar ul {
  margin: 0px;
  padding: 15px;
  text-align: center;
}
.nav1 {
  color: rgb(199, 199, 199);
  list-style: none;
  display: inline;
  padding: 10px 15px;
}
.nav1 a{
  padding: 15px 10px;
  color: #fff !important;
}
a {
  text-decoration: none;
  color: #fff;
}

.dropdown .dropbtn {
  font-size: 16px;  
  border: none;
  outline: none;
  color: white;
  padding: 0;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  
}



.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown{
  position: relative;
}
.dropdown-content{
  position: absolute;
  left: -29px;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: rgb(0, 0, 0);
  color: white;
  border-bottom: 1px solid #e0c37c;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: rgb(0, 0, 0);
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
  z-index: 9;
  
}


@media only screen and (max-width: 600px) {
  .dropdown-content{
    position: relative;
  }
  .navbar{
  display: none;

  }
}