.reset-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pass-change-form-ctr2 {
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 50%;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    min-height: 25vh;
    margin-top: 10px;
    margin-bottom: 10px;
}

.reset-pass-form {
    width: 60%;
    margin-bottom: 15px;
}

.reset-pass-form-input {
    width: 100%;
    height: 5vh;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.reset-form-submit-btn {padding: 1rem;
    margin: 1rem !important;
    margin-left: 0 !important;
    color: #000 !important;
    background-color: #c69736 !important;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    border: 0;
    width: 100%;
    font-size: 15px;
    border-radius: 10px;
}

.reset-form-submit-btn:hover {
    padding: 1rem;
    margin: 1rem !important;
    margin-left: 0 !important;
    color: #000 !important;
    background-color: #9e782b !important;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    border: 0;
    width: 100%;
    font-size: 15px;
    cursor: pointer;
    border-radius: 10px;
}