.products-body {
  background-color: #f8f5f0;
}
.strike-sign{
  display: none;
}
.strike{
  display: none !important;
}
.card {
  box-sizing: border-box;
  border: 0.5px #c69736 solid;
  background-color: #000;
  padding: 1rem;
  width: 25%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: auto;
  overflow: hidden;
  position: relative;
}

.card img {
  width: auto;
  height: 400px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.card-body {
  padding: 0 10px;
}

.card-body h2 {
  color: #c69736;
  text-align: center;
  letter-spacing: normal;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0.5rem;
}

.card-body > * {
  margin-bottom: 0.5rem;
}
.price {
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  margin: 15px 0;
}

.short {
  text-align: center;
  color: #fff;
  position: relative;
  bottom: 1rem;
}
.short i{
  font-style: normal;
}

.add-to-cart-button button {
  position: relative;
    left: 50%;
    transform: translate(-50%);
    padding: 3px 10px;
    border: 1px solid #c69736;
    font-weight: bold;
    letter-spacing: 1px;
    background: #c69736;
    color: #000;
    visibility: hidden;
    margin-bottom: 2rem;
    bottom: 0;
    font-size: 16px;
}


    

.second-right-div input, button {
    padding: 1rem;
}

.card:hover {
  box-shadow: inset 0 0 0 5px #c69736;
  /* border: 0.4rem solid #c69736; */
  margin: 0;
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
  overflow: hidden;
}
/* .card:hover .price {
  visibility: visible;
  font-size: 1.5rem;
  margin: 15px ​0 0;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
} */

.card:hover .add-to-cart-button button {
  visibility: visible;
  cursor: pointer;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 10;
  padding: 10px;
  border: 5px solid #fff;
}

.card:hover .short {
  visibility: hidden;
}

.card:hover h2 {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.add-to-cart-button button:hover {
  /* border: 4px solid #c69736; */
}

@media only screen and (max-width: 600px) {
  .card {
    width: 50%;
    height: auto;
  }

  .card img {
    height: 200px;
  }

  .price {
    visibility: visible;
  }
  .short {
    visibility: hidden;
  }
  .add-to-cart-button button {
    visibility: visible;
    padding: 0.2rem;
    margin-top: 1rem;
  }
  .card-body h2 {
    color: #c69736;
    text-align: center;
    /* letter-spacing: 0.2rem; */
    font-family: auto;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    text-transform: uppercase;
    /* margin: 0.5rem; */
}
}

.outOfStockOverlay {
    width: 100%;
    height: 100%;
    background-color: rgba(198, 151, 53, 0);
    position: absolute;
    z-index: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.outOfStockOverlay button {
    background-color: #c69736;
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 50%;
}