.contact-back{
    background-image: url('https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-modern-double-color-futuristic-neon-background-image_351866.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding-bottom: 30px;
}
.contact-cont{
   border: 3px solid goldenrod;
   margin: 30px;
   background-color: black;
   padding: 20px;
}
.contact-logo{
   background-color: black !important;
   padding: 5px 25% !important;
}
.contact-logo hr{
   background-color: goldenrod;
   border: none !important;
}
.contact-head{
   color:goldenrod ;
   text-transform: uppercase;
   text-align: center;
   font-weight: 1000;
}
.contact-para{
   text-align: center;
   color: white;
   font-weight: 800;
   font-size: 1.1em;
   padding: 0px 25%;
   letter-spacing: 1px;
   
}

.contact-logo p{
   letter-spacing: normal;
   color: goldenrod;
   margin: 0px 10px;
}
.contact-details-cont{
display: grid;
grid-template-columns: auto auto auto;
}
.contact-detail{
   border: 2px solid goldenrod;
   text-align: center;
   margin: 20px;
}
.contact-detail img{
width: 100%;
height: 300px;
}
.contact-p1{
   color: goldenrod;
   font-size: 1.5em;
   font-weight: 1000;

}
.contact-p2{
   color: white;
   font-weight: 500;
   word-spacing: 3px;
   letter-spacing: normal;
}
.contact-p3{
   color: goldenrod;
   word-spacing: 3px;
   letter-spacing: normal;
}

.corp-contact-details-cont {
    display: flex;
    grid-template-columns: auto auto auto;
}

.corp-contact {
    border: 2px solid goldenrod;
    text-align: center;
    margin: 20px;
    width: 100%;
}

.corp-contact img {
    width: 100%;
    height: 400px;
}

@media only screen and (max-width: 600px) {
.contact-details-cont{
   display: block;
}
.contact-para{
   padding: 0;
   font-weight: 400;
   font-size: 15px;
}
.contact-logo p{
   font-size: 25px;
}
.contact-logo{
   padding: 5% !important;
}
.contact-detail{
      margin: 20px 0;
      word-break: break-all;
 }
 .contact-p1{
    padding: 0px 10px;
 }
 .contact-p2{
   padding: 0px 10px;
}
 .contact-p3{
   padding: 0px 10px;
}

.corp-contact-details-cont {
    flex-direction: column;
    align-items: center;
}
}
