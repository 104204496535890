.text_rtl {
    direction: rtl;
}

.shipping-policy-table {
    border: 2px solid #C69736;
    display: block;
    color: #fff;
    padding: 15px 35px;
    text-align: left;
    border-collapse: collapse;
    width: 100%;
}

.shipping-policy-table tr td {
    color: black;
}