@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&family=Urbanist:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
body {
  margin: 0;
  font-family: 'Gill Sans W04 Roman', Calibri, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: 'Gill Sans W04 Roman', Calibri, sans-serif;
}



/* Global */

.App {
  text-align: center;
}
body{
  scroll-behavior: smooth;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* BREADCRUMBS */

.MuiTypography-colorTextSecondary {
  color: #fff !important;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.MuiTypography-colorTextPrimary {
  color: #fff !important;
}

button {
  cursor: pointer;
}


.topbar-box {
  font-family: "Poppins", sans-serif;
  background-color: #000;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%
}
#username{
  color:#e0c37c;
}
.topbar-language {
  display: flex;
  width: 100%;
}
.topbar-icons ul li:first-child{
  padding-top: 9px;
}

.topbar-currancy-pic {
  width: 45px;
  height: 25px;
}
.first-li div{
  margin-top: 25px;
}
.second-li {
  margin-top: 25px;
    display: inline-flex;

}
.topbar-currancy-name p {
  color: rgb(199, 199, 199);
  border-right: 1px solid rgb(199, 199, 199);
  margin: 0px;
  margin-left: 20px;
  padding-right: 10px;
}

.topbar-language-pic svg {
  color: gray;
  font-size: 1.5em;
  margin-left: 10px;
}

.topbar-language-name p {
  color: rgb(199, 199, 199);
  margin: 0px;
  padding-left: 10px;
}

.topbar-logo {
  height: 135px;
  width: 220px;
  margin-top: 5px;
}

.topbar-logo-box {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.topbar-icons {
  width: 100%;
  text-align: right;
  cursor: pointer;
}
.topbar-icons ul {
  margin-left: 0;
  margin-right: 15px;
  float: right;
}
.topbar-icons ul li {
  list-style: none;
  display: inline;
  padding: 10px;
}
.topbar-icons ul li svg {
  color: #e0c37c;
  font-size: 1.5em;
}

.dropdown-menu.show {
     display: flex;
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    /* transform: translate(1272px, 82px) !important; */
    flex-direction: column;
    z-index: 1000;
    background: #000;
    border: 1px solid #c69736;
    padding: 0;
    margin-top: 1rem;
    text-align: left;
    width: 170px;
}

.dropdown-menu a {
  border-bottom: 1px solid gray;
  padding: 5px 15px;
}

.counter-cart {
  position: relative;
  left: 100%;
  bottom: 55%;
  border: 1px solid #c69736;
  border-radius: 81%;
  padding-left: 0rem;
  margin-left: 0rem;
  text-align: center;
  background: #c69736;
  color: #000;
  font-size: 0.7rem;
  font-weight: bolder;
}



/*
login
*/
.container {
  z-index: 9999;
  width: 60%;
  height: auto;
  background-color: #fff;
  display: flex;
  position: absolute;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
}

.left-box {
  width: 50%;
  border: 1px solid black;
  color: #000;
  background-color: #c69736;
}

.right-box {
  width: 50%;
  color: #c69736;
  background: #000;
  border: 1px solid black;
}

.login-buttons {
  position: relative;
  top: 2%;
  text-align: center;
}

.login-buttons h1 {
  color: #000;
  text-align: start;
  padding: 1rem;
  padding-left: 2.8rem;
}

.login-buttons button {
  width: 80%;
  padding: 1rem;
  margin-bottom: 2rem;
  background-color: transparent;
  border: 2px solid #000;
  color: #000;
  display: none;
  font-size: 16px;
}
.or-text{
  display: none;
}
.upper-buttons {
  width: 80%;
  display: flex;
  margin: 0rem;
  margin-left: 2.3rem;
}

.first-button {
  background-color: transparent;
  border: 1px solid #c69736;
  padding: 0.2rem;
  width: 50%;
  color: #c69736;
}

.second-button {
  border: 1px solid #000;
  background-color: #c69736;
  color: #000;
  padding: 0.2rem;
  width: 50%;
}

.form-credentials {
  width: 80%;

  height: auto;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-top: 1rem;
}

.form-signup-credentials {
  width: 80%;

  height: auto;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-top: 3rem;
  display: none;
}

.form-credentials h1 {
  margin-bottom: 2rem;
}

.form-credentials p {
  font-size: 14px;
}

.form-credentials input {
  border: none;
  border-bottom: 1px solid gray;
  margin-bottom: 1rem;
  width: 90%;
  padding: 0.7rem;
}

.form-credentials button {
  padding: 1rem;
  background-color: #c69736;
  border: none;
  color: #000;
  font-size: 20px;
  margin-top: 2rem;
  width: 97%;
}

.form-signup-credentials input {
  border: none;
  border-bottom: 1px solid gray;
  margin-bottom: 1rem;
  width: 100%;
  padding: 0.5rem;
}

.form-signup-credentials button {
  padding: 1rem;
  background-color: #c69736;
  border: none;
  color: #000;
  font-size: 20px;
  margin-top: 0.2rem;
  width: 105%;
}
.lower-info {
  text-align: center;
}

.lower-info p {
  margin-top: 1rem;
  font-size: 12px;
  text-align: center;
  text-decoration: underline;
}
.userlogo {
  background-color: #fff;
  width: 30%;
  height: 17vh;
  border-radius: 50%;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.userlogo img {
  width: 200px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.close-button {
  position: absolute;
  top: -45%;
}

.bottom-part div {
  display: inline;
}

.just-border-5 {
  border-top: 1px solid #000;
  height: auto;
  margin-top: 1.5rem;
  width: 85% !important;
  position: relative;
  left: 10%;
}

.just-border-6 {
  border-top: 1px solid #000;
  height: auto;
  margin-top: 1.5rem;
  width: 85% !important;
  position: relative;
  right: 10%;
}

.last-button {
  width: 80%;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: transparent;
  border: 2px solid #000;
  color: #000;
  font-size: 16px;
  margin-left: 2.5rem;
}
.first-li-logout{
  padding-top: 35px !important;
}
.sign-btn svg{
  padding-right: 5px;
}
.sign-btn span{
  font-size: 20px;
}
.last-button svg{
  padding-right: 5px;
}
.last-button span{
  font-size: 20px;
}
.login-container{
  display: flex;
  position: fixed;
}
.or-div{
  display: none !important;
}
.modal-closebtn{
  font-size: 3rem;
    position: relative;
    left: 75%;
}
.form-credentials input[type="email"]{
  text-transform: lowercase;
}
.form-signup-credentials input[type="email"]{
  text-transform: lowercase;
}
@media only screen and (min-width: 601px) {
  .menu-toggle{
    display: none !important;
  }
  .user-icon{
    padding-top: 45px !important;
  }
}

@media only screen and (max-width: 600px) {
  .form-credentials{
    padding: 0 45px;
    left: auto;
    transform: none;
    margin-top: 0;
  }
  .login-container{
    top: 0px;
    transform: translate(0px);
    left:0px;
    height:100vh;
    overflow:hidden;
    z-index: 1000000000000000000000;
  }
  .modal-closebtn {
    font-size: 2rem;
    left: 85%;
    top: 20px;
}
  .activebox{
    display: block !important;
  }
  .login-container{
    display: block;
    position: fixed !important;
  }
  .left-box{
    width: auto;
    text-align: center;

  }
  .login-buttons h1{
    font-size: 18px;
    padding-left: 0;
    text-align: center;
    padding-top: 0;
  }
  .right-box{
    width: auto;
  }
  .first-li-logout{
    padding-top: 0 !important;
  }
  .container {
    z-index: 999999;
    width: 100%;    
    height: auto;
    background-color: #fff;
    position: absolute;
    
  }
  .topbar-language {
   position: absolute;
   top: 5px;
   border-bottom: 1px solid #c69736;
   left: 0px;
   width: 100%;
   background-color: black;
  }
  .topbar-logo-box img {
    height: 100%;
    width: 100%;
  }

  .topbar-icons {
    width: 100%;
    margin: 0;
    font-size: 15px;
    margin-top: -5rem;
    padding-right: 0em;
  }

  .topbar-icons ul {
    margin-left: 110px;
    margin-right: 35px;
    margin-bottom: -15px;
    margin-top: 30px;
}

  .topbar-box {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }

  .topbar-logo-box {
    width: 28%;
    z-index: 9;
    padding-top: 45px;
  }
  .topbar-currancy-name{
    padding-top: 10px;
  }
  .topbar-language-pic{
    padding-top: 11px;
  }
  .counter-cart {
    width: 60%;
    margin-top: -0.5rem;
  }
  .last-button{
    margin-left: 20px;
    font-size: 0.8em;
    padding: 10px;
  }
  .last-button svg{
    right: 10% !important;
  }
  .login-buttons button{
    font-size: 12px;
    width: 42%;
    padding: 5px;
    margin-bottom: 0;
    margin-right: 10px;
  }
  .sign-btn span {
    font-size: 13px;
    display: block;
}
.last-button {
  width: auto;
  margin-left: 0;
}
.last-button span {
  font-size: 13px;
}
  .first-li div {
    margin-top: -10px;
}
.second-li{
  margin-top: -10px;
}

.nav1 a {
  padding: 10px 30px !important;
  display: block;
}
.nav1{
  display: block !important;
  text-align: left;
  padding: 0 !important;
  border-bottom: 2px solid #c69736;
}
.navbar ul{
  padding: 0 !important;
}
.menu-toggle{
  position: absolute;
  right: -40px;
  top: 0;
  z-index: 10000000000000;
}
.dropdown .dropbtn{
  width: 100%;
  text-align: left;
  padding: 10px 30px !important;
  border-bottom: 1px solid #c69736 !important;
}
.dropdown-content{
  position: initial;
}

.dropdown-content a{
    padding: 10px 50px !important;
  }
  .form-credentials h1{
    font-size: 20px;
  }
  .form-credentials input{
    margin-bottom: 0;
  }
  .form-credentials button{
    margin-top: 1rem;
  }
  .topbar-language-name .dropdown .dropbtn{
    border-bottom: none !important;
    padding: 10px !important;
  }
  .topbar-language-name .dropdown .dropdown-content{
    position: absolute;
  } 
}

.marquee-container {
    overflow: hidden;
    background-color: #c69736;
    padding: 0px;
    margin: 0px;
    height: 2rem;
    display: flex;
    align-items: center;
}

.marquee {
    display: inline-block;
    white-space: nowrap;
    animation: marqueeAnimation 15s linear infinite;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    width: 100%;
    padding: 0px;
    margin: 0px;
}

@keyframes marqueeAnimation {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-100%);
    }
}
.navbar {
  font-family: "Poppins", sans-serif;
  background-color: #000;
  border-top: 2px solid rgb(255, 255, 255);
}
.navbar ul {
  margin: 0px;
  padding: 15px;
  text-align: center;
}
.nav1 {
  color: rgb(199, 199, 199);
  list-style: none;
  display: inline;
  padding: 10px 15px;
}
.nav1 a{
  padding: 15px 10px;
  color: #fff !important;
}
a {
  text-decoration: none;
  color: #fff;
}

.dropdown .dropbtn {
  font-size: 16px;  
  border: none;
  outline: none;
  color: white;
  padding: 0;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  
}



.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown{
  position: relative;
}
.dropdown-content{
  position: absolute;
  left: -29px;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: rgb(0, 0, 0);
  color: white;
  border-bottom: 1px solid #e0c37c;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: rgb(0, 0, 0);
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
  z-index: 9;
  
}


@media only screen and (max-width: 600px) {
  .dropdown-content{
    position: relative;
  }
  .navbar{
  display: none;

  }
}
/* Sliderbottom */

.sliderbottom-box {
  background-color: #000;
  /* height: 68vh; */
}
.owl-prev {
  background-color: #c69736 !important;
}
.owl-next {
  background-color: #c69736 !important;
}
.prev-slides i {
  font-size: 14px;
}

.sliderbottom-head1 p,
.sliderbottom-head2 p,
.sliderbottom-head3 p,
.sliderbottom-icons div,
.sliderbottom-know p {
  color: rgb(255, 255, 255);
  text-align: center;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.sliderbottom-head1 p {
  font-size: 1.2em;
  color: #c69736;
  text-transform: uppercase;
  margin-top: 3rem;
}
.sliderbottom-head2 p {
  font-size: 2em;
  font-style: italic;
  font-family: "Freight-Rg";
}
.sliderbottom-head3 {
  width: 100%;
  text-align: center;
}
.sliderbottom-head3 p {
  font-size: 1.2em;
  padding: 0px 29%;
  font-weight: 100;
}
.sliderbottom-flex div {
  list-style: none;
  display: inline;
  padding: 30px;
}
.sliderbottom-flex div p {
  margin: 0px;
  font-weight: 400;
}
.sliderbottom-flex div svg {
  font-size: 2em;
  color: #c69736;
}
.sliderbottom-flex {
  display: flex;
  justify-content: center;
}

.sliderbottom-know p {
  font-style: italic;
  color: #c69736;
  border: 2px solid #c69736;
  padding: 5px;
  margin-bottom: 30px;
  width: 100%;
}

.sliderbottom-know{
  max-width: 200px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}


.button-know {
  font-family: "Courier New", Courier, monospace;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  padding-bottom: 1rem;
  color: #fff;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 0.1rem;
  cursor: pointer;
  border-bottom: 1px solid #c69736;
  width: 10%;
}
/* Lower Banner */

.largeimage {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  min-height: 520px;
}

.largeimage {
  color: #fff;
}

.inner {
  font-family: "Poppins", sans-serif;
  position: relative;
  /* left: 50%; */
  width: 40%;
  /* top: 49%; */
  margin: 8em 6em;
  float: right;
}

.inner h1 {
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  color: #c69736;
  margin: 10px;
  line-height: normal;
  letter-spacing: normal;
}

.inner p {
  text-align: center;
  font-size: 18px;
  margin-bottom: 2.9rem;
  letter-spacing: 1px;
}

.inner button {
  text-align: center;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  padding: 1rem;
  border: 2px solid #c69736;
  background-color: transparent;
  color: #c69736;
  font-weight: 500;
  letter-spacing: 0.1rem;
  cursor: pointer;
  font-size: 18px;
}

.borderbottom {
  border-bottom: 3px solid #c69736;
  width: 40%;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 2rem;
}

/* Categories */

.categories {
  /* height: 88vh; */
  width: 100%;
  border-top: 2px solid #fff;
  text-align: center;
  overflow: hidden;
}

.categories h1 {
  color: #fff;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-bottom: 2rem;
  margin-top: 0;
}

.categories h3 {
  color: #c69736;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-top: 2rem;
  margin-bottom: 10px;
}

.row-images {
  display: flex;
  /* height: 40vh; */
  margin-bottom: 60px;
}

.firstimage {
  background-image: url(/static/media/fulvio-ciccolo-iDDGJt3veps-unsplash.c19031ac.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 40vh;
  width: 100%;
  border: 5px solid #c69736; /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
  transition: all 1s ease;
}
.y {
  height: 40vh;
  width: 25%;
  margin: 0;
  margin-left: 0;
}
.firstimage:hover {
  transform: scale(1.05);
}

.secondimage {
  background-image: url(/static/media/miska-sage-GnF5Xpu-764-unsplash.1343e038.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 40vh;
  width: 25%;
  margin: 0.5rem; /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
  transition: all 1s ease;
}

.secondimage:hover {
  transform: scale(1.05);
}

.thirdimage {
  background-image: url(/static/media/miska-sage-UpoUiGj-qg8-unsplash.4fa37a2f.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 40vh;
  width: 25%;
  margin: 0.5rem; /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
  transition: all 1s ease;
}

.thirdimage:hover {
  transform: scale(1.05);
}

.fourthimage {
  background-image: url(/static/media/sara-saleh-cpisiUDKAqE-unsplash.746a7f71.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 40vh;
  width: 25%;
  margin: 0.5rem;
  margin-right: 2rem; /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
  transition: all 1s ease;
}

.fourthimage:hover {
  transform: scale(1.05);
}
.info-label {
  text-transform: uppercase;
}
.firstimage:hover .info-label,
.secondimage:hover .info-label,
.thirdimage:hover .info-label,
.fourthimage:hover .info-label {
  font-size: 1.05rem;
}

.row-images button {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  border: 2px solid #c69736;
  font-weight: 550;
  letter-spacing: normal; /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
  transition: all 1s ease;
}

.categories-button {
  padding: 1rem;
  border: 2px solid #c69736;
  background-color: transparent;
  color: #c69736;
  font-weight: 500;
  margin-top: 4rem;
  margin-bottom: 4rem;
  letter-spacing: 0.1rem;
  cursor: pointer;
  font-size: 18px; /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
  transition: all 1s ease;
}

/* BESTSELLER SECTION */

.bestseller-section {
  /* height: 110vh; */
  display: inline-block;
  text-align: center;
  margin-top: -3em;
  width: 100%;
}

.bestseller-section img {
  margin-top: 1rem; /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
  transition: all 1s ease;
  width: 100%;
}

.divide1:hover img { /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
  transform: scale(1.05);
}

.divide:hover img { /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
  transform: scale(1.05);
}

.divide2:hover img { /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
  transform: scale(1.05);
}

.divide4:hover img { /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
  transform: scale(1.05);
}

.first:hover img { /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
  transform: scale(1.05);
}

.bestseller-section h1 {
  color: #fff;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-bottom: 3rem;
}

.bestseller-section h3 {
  color: #c69736;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-top: 3rem;
  margin-bottom: 10px;
}

.bestseller-innersection {
  display: flex;
}

.bestseller-innersection .first {
  width: 25%;
  border: 2px solid #fff;
  border-left: none;
  /* height: 90vh; */
}

.bestseller-innersection .divide {
  height: 45vh;
  width: 100%;
  border-bottom: 2px solid #fff;
}

.bestseller-innersection .divide1 {
  height: 45vh;
  width: 100%;
}

.bestseller-innersection .divide2 {
  height: 45vh;
  width: 100%;
  border-bottom: 2px solid #fff;
}

.bestseller-innersection .divide3 {
  height: 45vh;
  width: 100%;
}

.first h3 {
  text-transform: uppercase;
  margin-top: 0;
  font-size: 20px;
  font-weight: 400;
}

.first h4 {
  font-weight: lighter;
  color: #fff;
  margin: 0;
  font-size: 20px;
  letter-spacing: 0.2rem;
}

/* TESTIMONIAL */

.testimonial-heading {
  color: #fff;
  text-align: center;
  position: relative;
  top: 30%;
}
.testimonial-heading h1 {
  color: #fff;
  font-weight: lighter;
  letter-spacing: 0.3rem;
}
.bestseller-img-1 {
  padding-top: 0rem;
  height: 28vh;
  width: auto !important;
}
.bestseller-img-2 {
  padding-top: 0rem;
  height: 70vh;
}
@media only screen and (max-width: 600px) {
  .testimonial-para1 p,
  .testimonial-para2 p,
  .testimonial-para3 p {
    padding: 0 20px !important;
  }
  .inner p {
    font-size: 14px;
    padding: 3px 15px;
  }
  .sliderbottom-head1 p {
    margin-top: 20px;
  }
  .sliderbottom-head2 p{
    margin: 10px 0;
  }
  .sliderbottom-box {
    background-color: black;
    height: auto;
    padding: 0px 10px;
  }
  .sliderbottom-head3 p {
    font-size: 0.9em;
    padding: 0px 10px;
    font-weight: 100;
  }
  .sliderbottom-flex div {
    width: 25%;
    vertical-align: top;
    padding: 15px;
    display: inline-block;
    /* margin: 0rem; */
    /* padding: 37px; */
    /* position: relative; */
    /* left: -19px; */
    /* font-size: 16px; */
    /* text-align: center; */
    word-break: break-all;
  }
  .sliderbottom-flex div p {
    font-size: 13px;
    text-align: center;
  }

  .categories {
    height: auto;
  }

  .row-images {
    display: block;
    height: auto;
  }

  .firstimage {
    width: 43%;
    display: inline-block;
    height: 19vh;
  }
  .secondimage {
    width: 83%;
    margin: 2rem;
  }
  .thirdimage {
    width: 83%;
    margin: 2rem;
  }
  .fourthimage {
    width: 83%;
    margin: 2rem;
  }

  .categories-button {
    padding: 0.7rem;
    border: 2px solid #c69736;
    background-color: transparent;
    color: #fff;
    font-weight: bold;
    margin-top: 1rem;
    letter-spacing: 0.1rem;
    cursor: pointer;
    margin-bottom: 2rem;
  }
  .largeimage {
    height: auto;
  }
  .inner button {
    margin-bottom: 1rem;
  }

  .inner {
    position: relative;
    left: 0;
    width: auto;
    padding-top: 25px;
    min-height: inherit;
    top: 0;
    background: #000000c9;
    margin: 0;
  }
  .owl-carousel .owl-item img {
    height: auto;
  }
  .owl-theme .owl-dots .owl-dot span {
    margin-top: -5px;
    background-color: #fff !important;
  }
  .owl-theme .owl-dots .owl-dot.active span {
    background: #c69736 !important;
  }
  .bestseller-innersection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .bestseller-section {
    height: auto;
  }

  .bestseller-section img {
    height: 150px;
    width: 150px;
  }

  .bestseller-innersection .first {
    width: 100%;
    height: auto;
    border-left: 2px solid #fff;
  }
  .bestseller-innersection .divide {
    height: auto;
  }
  .bestseller-innersection .divide1 {
    height: auto;
  }
  .bestseller-innersection .divide2 {
    height: auto;
  }
  .bestseller-innersection .divide3 {
    height: auto;
  }
  .first h4 {
    margin-bottom: 15px;
  }
  .sliderbottom-flex {
    display: inline-block;
  }
  .sliderbottom-know p {
    transform: inherit;
    width: auto;
    margin: 25px 40px;
    left: auto;
  }
  .row-images button {
    word-break: break-word;
    margin: 0 4px;
  }
  .inner h1 {
    font-size: 2rem;
  }
  .largeimage {
    background-size: cover;
    min-height: 350px;
  }
}

.mainslider-heading {
  padding: 10px 50px;
  color: rgb(82, 82, 82);
  font-weight: 700;
  font-size: 2em;
}

.owl-next,
.owl-prev {
  height: 50px;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: rgba(0, 0, 0, 0);
}
.open-galary {
  float: right;
  color: rgb(56, 175, 255);
  display: none;
}
.open-galary p {
  color: rgb(56, 175, 255);
  margin: 0px;
}

@media only screen and (max-width: 600px) {
  .mainslider-heading {
    padding: 10px 20px;
  }
  
  .open-galary {
    display: inline;
    margin-top: 15px;
  }
  .mainslider-heading {
    font-size: 1.4em;
  }
}

.owl-next {
  position: absolute;
  width: 50px;
  margin: 0px;
  font-size: 0.5em !important;
  right: 10px;
  border-radius: 50% !important;
  height: 50px;
  color: white !important;
  background-color: black !important;
  top: 44%;
}

.owl-prev {
  position: absolute;
  width: 50px;
  margin: 0px;
  font-size: 0.5em !important;
  color: white !important;
  background-color: black !important;
  left: 10px;
  border-radius: 50% !important;
  height: 50px;
  top: 44%;
}

.item,
.item img {
  width: 100%;
  height: auto;
}

.owl-theme .owl-nav [class*="owl-"] {
  font-size: 3em;
  margin: 0px;
}

.nav-btn {
  font-size: 3em;
}

.owl-dots {
  position: absolute;
  top: 87% !important;
  left: 45%;
}

.owl-dots button span {
  width: 8px !important;
  height: 8px !important;
  background-color: black !important;
  border: 5px solid black;
}

.owl-stage-outer {
  position: relative !important;
}

.owl-nav {
  margin-top: 0px !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #c69736 !important;
}

@media only screen and (max-width: 600px) {
  .item,
  .item img {
    width: 100%;
    height: auto;
  }
}

.owl-next {
  position: absolute;
  width: 35px;
  margin: 0px;
  font-size: 15px !important;
  right: 10px;
  border-radius: 50% !important;
  height: 35px;
  color: white !important;
  background-color:#c69736b0 !important;
  top: 44%;
}

.owl-prev {
  position: absolute;
  width: 35px;
  margin: 0px;
  font-size: 15px !important;

  color: white !important;
  background-color:#c69736b0 !important ;
  left: 10px;
  border-radius: 50% !important;
  height: 35px;
  top: 44%;
}
.item,
.item img {
  width: 100%;
  height: auto;
}
.owl-theme .owl-nav [class*="owl-"] {
  font-size: 3em;
  margin: 0px;
}
.nav-btn {
  font-size: 3em;
}
.owl-dots {
  position: absolute;
  top: 95%;
  left: 45%;
}
.owl-dots button span {
  width: 10px !important;
  height: 10px !important;
  display: none;
}
.owl-stage-outer {
  position: relative !important;
}
.owl-nav {
  margin-top: 0px !important;
}

.owl-themes {
  font-family: "Poppins", sans-serif;
  width: 70% !important;
  height: 40vh;
  padding: 0px 00px !important;
}
.testimonial {
  font-family: "Poppins", sans-serif;
  text-align: center;
  width: 100%;
  border-bottom: 2px solid #fff;
display: flex;
padding: 50px 0px;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.testimonial .owl-carousel.owl-loaded {
  border: 3px solid #c69736;
  border-radius: 15px;
}

.items {
  width: 100%;
  
  padding: 25px 0;
  background-color: rgba(29, 29, 29, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.items div {
  justify-content: center;
}
.testimonial-img img {
  width: 75px !important;
  height: 75px;
  border-radius: 50%;
  border: 2px solid #c69736;
}
.testimonial-para1 p,
.testimonial-para2 p,
.testimonial-para3 p {
  color: white;
  margin: 0px;
  padding: 0px 100px;
  margin-top: 20px !important;
}
.testimonial-para2 p {
  font-size: 20px;
  text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
  .testimonial {
    background-image: none !important;
    display: inline-block;
    height: auto;
    margin: 30px 0;
  }
  .testimonial .owl-themes {
    font-family: "Poppins", sans-serif;
    width: 99% !important;
    height: auto;
    padding: 0px 00px !important;
}
}

.footer {
  display: flex;
  margin-left: 4rem;
  background-color: #000;
}

.footer h2 {
  color: #c69736;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.footer ul{
  padding: 0;
  padding-left: 10px;
}

.footer p {
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-align: left;
}
.footer-bottom{
    display: flex;
    background: #000;
    margin: 0;
    padding: 10px 0;
    border-top: 1px solid #c69736;
    text-align: center;
    justify-content: center;
}
.what_txt{
  display: initial;
}
.what_txt i{
  color: #ffffff;
}
.whatsapp_div {
  position: fixed;
  bottom: 20px;
  color: #ffffff;
  left: 25px;
  z-index: 999;
  border-radius: 100px;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 7px 12px !important;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 14%);
  text-transform: uppercase;
  box-shadow: 0 4px 9px 0 rgb(0 0 0 / 20%);
  background: #25d366;
  border: 2px solid #ffffff;
  animation: animate1 2s linear infinite;
}
.footer-categories a{
  text-transform: uppercase;
}
.whatsappbtn {
  
}
.copyName{
  color: #c69736;
  margin: 0;
  margin-left: 4rem;
  display: inline-block;
}
.designName{
  color: #c69736;
  margin: 0;
  float: right;
  margin-right: 4rem;
  display: inline-block;
}
.col-1 {
  width: 30%;
  margin-top: 3rem;
}
.col-1 img {
  height: 150px;
}

.col-2 {
  width: 23%;
}

.col-2 h2 {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.col-3 {
  width: 23%;
}

.col-3 h2 {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.col-4 {
  width: 23%;
  margin-right: 2rem;
}

.col-4 h2 {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.footerborder {
  position: relative;
  border-bottom: 2px solid #c69736;
  width: 30%;
  left: 12%;
  margin-bottom: 2rem;
}

.col-4 div {
  color: #fff;
}

.flex {
  display: flex;
  justify-content: left;
  align-items: center;
}

.flex i {
  color: #c69736;
  font-size: 25px;
  padding-right: 30px;
}

@media only screen and (max-width: 600px) {
  .footer {
    display: block;
    height: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .col-1 {
    width: 100%;
  }
  .col-1 img {
    height: 40vmin;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
  .col-2 {
    width: 100%;
    text-align: center;
    margin-left: 0rem;
  }
  .col-3 {
    width: 100%;
    text-align: center;
    margin-left: 0rem;
  }
  .col-4 {
    width: 100%;
    text-align: center;
    margin-left: 0rem;
  }
  .footerborder {
    position: relative;
    border-bottom: 2px solid #c69736;
    width: 30%;
    left: 50%;
    transform: translate(-50%);
    margin-bottom: 2rem;
  }

  .footer h2,
  ul {
    position: relative;
    left: 0;
    transform: none;
    padding-left: 0;
    justify-content: center !important;
  }
  .footer p{
    text-align: center;
  }
  .copyName{
    margin-left: 0;
  }
  .designName{
    float: none;
    margin-right: 0;
  }
  .whatsapp_div{
    bottom: 55px;
  }
  .flex i{
    padding: 0px 10px;
  }
}

.aboutus-container {
  background-image: url(/static/media/back.99bd1954.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 140px;
  display: flex;
  flex-direction: column;
}

.aboutus-head h1 {
  font-family: "Gill Sans W04 Roman", Calibri, sans-serif;
  font-size: 4rem;
  padding: 0px 25%;
  font-weight: normal;
  line-height: 1em;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  letter-spacing: 0.06667em;
  text-transform: uppercase;
  color: #000;
}

.aboutus-head {
  padding: 1.25em 0;
  background: #fff;
  mix-blend-mode: lighten;
}

.need-to-hide{
  display: none;
}

.aboutus-head::after {
  display: table;
  clear: both;
  content: "";
}

.aboutus-container-box {
  display: flex;
  flex-direction: column;
  padding: 80px;
  padding-top: 0px;
  background-color: white;
}

.aboutus-logo hr,
.aboutus-logo img {
  display: inline;
}

.aboutus-logo {
  display: flex;
  align-items: center;
  padding: 0px 25%;
  background-color: white;
  padding-top: 200px;
}

.aboutus-logo hr {
  width: 100%;
  height: 1px;
  border: none;
  border-top: 1px solid rgb(167, 167, 167);
  justify-content: center;
}

.aboutus-logo img {
  width: 50px;
  height: 50px;
  margin: 0px 40px;
}

.about-para {
  color: #555;
  padding: 20px 25%;
  text-align: center;
  font-weight: 800;
  font-size: 1.1em;
  line-height: 30px;
}

.aboutus-form form {
  display: flex;
  flex-direction: column;
  padding: 30px 25%;
}

.aboutus-form form input {
  padding: 12px 16px;
}

.aboutus-form form label {
  font-size: 0.8em;
  margin: 10px 0px;
}

.sub-button {
  margin: 10px 20%;
  color: white;
  background: #6d4d15;
  border: none;
  font-size: 0.8em;
}

.about-us-decor {
  display: flex;
  justify-content: start;
}

.about-us-decor div {
    width: unset;
}

.about-us-decor div img {
  height: 400px;
  width: 400px;
  margin: 20px;
}

.about-us-decor div h5 {
  color: #a87e3b;
  font-size: 1.1em;
  letter-spacing: 7px;
  text-align: center;
}

.about-us-decor div p {
  color: #0000008a;
  text-align: center;
  font-weight: 1000;
}

@media only screen and (max-width: 900px) {
  .about-us-decor div img {
    height: 250px;
    width: 100%;
    margin: 20px;
  }
  .about-us-decor {
    flex-direction: column;
  }
  .aboutus-logo {
    padding: 20px;
  }
  .aboutus-head h1 {
    padding: 10px;
    font-size: 3.5em;
  }
  .about-para {
    padding: 20px;
  }
  .aboutus-form form {
    padding: 30px;
  }
  .abooutus-images div:nth-child(2){
order: 1;
  }
  .abooutus-images div:nth-child(1){
order: 2;

  }
}

@media only screen and (max-width: 600px) {
    .aboutus-container {
    padding: 20px;
    }
.aboutus-container-box {
padding: 10px;
}
.about-us-decor div img {
    height: 250px;
    width: 90%;
    margin: 10px;
  }
}
.loaderCtr {
    width: 100%;
    min-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    /* background-color: aqua; */
}

.loaderText {
    margin-top: 30px;
    padding: 10px;
    color: white;
    text-align: center;
}

.lds-spinner {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
}

.lds-spinner div {
    transform-origin: 60px 60px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 0px;
    left: 57px;
    width: 6px;
    height: 25px;
    border-radius: 20%;
    background: #fff;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
.products-body {
  background-color: #f8f5f0;
}
.strike-sign{
  display: none;
}
.strike{
  display: none !important;
}
.card {
  box-sizing: border-box;
  border: 0.5px #c69736 solid;
  background-color: #000;
  padding: 1rem;
  width: 25%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: auto;
  overflow: hidden;
  position: relative;
}

.card img {
  width: auto;
  height: 400px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.card-body {
  padding: 0 10px;
}

.card-body h2 {
  color: #c69736;
  text-align: center;
  letter-spacing: normal;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0.5rem;
}

.card-body > * {
  margin-bottom: 0.5rem;
}
.price {
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  margin: 15px 0;
}

.short {
  text-align: center;
  color: #fff;
  position: relative;
  bottom: 1rem;
}
.short i{
  font-style: normal;
}

.add-to-cart-button button {
  position: relative;
    left: 50%;
    transform: translate(-50%);
    padding: 3px 10px;
    border: 1px solid #c69736;
    font-weight: bold;
    letter-spacing: 1px;
    background: #c69736;
    color: #000;
    visibility: hidden;
    margin-bottom: 2rem;
    bottom: 0;
    font-size: 16px;
}


    

.second-right-div input, button {
    padding: 1rem;
}

.card:hover {
  box-shadow: inset 0 0 0 5px #c69736;
  /* border: 0.4rem solid #c69736; */
  margin: 0;
  transition: all 1000ms ease;
  overflow: hidden;
}
/* .card:hover .price {
  visibility: visible;
  font-size: 1.5rem;
  margin: 15px ​0 0;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
} */

.card:hover .add-to-cart-button button {
  visibility: visible;
  cursor: pointer;
  transition: all 500ms ease;
  z-index: 10;
  padding: 10px;
  border: 5px solid #fff;
}

.card:hover .short {
  visibility: hidden;
}

.card:hover h2 {
  transition: all 500ms ease;
}

.add-to-cart-button button:hover {
  /* border: 4px solid #c69736; */
}

@media only screen and (max-width: 600px) {
  .card {
    width: 50%;
    height: auto;
  }

  .card img {
    height: 200px;
  }

  .price {
    visibility: visible;
  }
  .short {
    visibility: hidden;
  }
  .add-to-cart-button button {
    visibility: visible;
    padding: 0.2rem;
    margin-top: 1rem;
  }
  .card-body h2 {
    color: #c69736;
    text-align: center;
    /* letter-spacing: 0.2rem; */
    font-family: auto;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    text-transform: uppercase;
    /* margin: 0.5rem; */
}
}

.outOfStockOverlay {
    width: 100%;
    height: 100%;
    background-color: rgba(198, 151, 53, 0);
    position: absolute;
    z-index: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.outOfStockOverlay button {
    background-color: #c69736;
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 50%;
}
.row-center {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.breadcrumbs {
  width: 100%;
  border-top: 2px solid #fff;
}
.mrp-text {
  display: none;
}
.mrp {
  display: none !important;
}
.detail-section {
  box-sizing: border-box;
  height: auto;
  width: 90%;
  display: flex;

  position: relative;
  left: 50%;
  transform: translate(-54%);
  padding-bottom: 1rem;
}
#detail-cr {
  scroll-behavior: smooth;
}
.detail-section-logo p {
  color: #c69736;
  font-size: 1rem;
  letter-spacing: 0.2rem;
  text-align: center;
}
.details-desc {
  color: white;
  padding: 2rem;
  margin: 0px 10rem;
}
.detail-section h1 {
  color: #c69736;
  text-align: center;
  letter-spacing: 0.7rem;
}

.detail-section-logo {
  width: 80%;
  height: 40vh;
  background-color: rgb(10, 10, 10);
  margin-top: 7rem;
}

.detail-section-image {
  width: 120%;
  height: 70vh;
  overflow-y: scroll;
}
.detail-section-image::-webkit-scrollbar-track {
  border: 0px solid #000;
  padding: 0px;
  background-color: #404040;
}

.detail-section-image::-webkit-scrollbar {
  width: 0px;
}

.detail-section-image::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #737272;
  border: 0px solid #000;
}
.detail-section-image img {
  height: 75vh;
}
.detail-section-text {
  width: 100%;
  height: auto;
  padding-left: 1rem;
}

.logo-internal-section {
  display: flex;
  margin-top: 2rem;
}

.just-logo img {
  width: 100px;
  height: 100px;
}

.just-logo {
  height: auto;
  width: 100%;
}

.just-border {
  border-top: 1px solid #c69736;
  height: auto;
  width: 100%;
  margin-top: 3rem;
  position: relative;
}

.detail-section-image img {
  width: auto;
  margin-bottom: 10px;
}
.detail-section-image {
  width: 100%;
}

.detail-section-inner {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.detail-section-inner p {
  color: #fff;
  text-align: justify;
  width: 100%;
}
.detail-section-inner h4 {
  font-weight: 500;
  margin-top: 5px;
}
.size-div {
  width: 30%;
  display: inline-block;
}
.size-div p {
  margin: 0;
}
.qty-text {
  color: rgb(198, 151, 54) !important;
  font-size: 1.4em;
  margin: 0;
  display: inline;
}
.select-qty {
  width: auto;
  background: transparent;
  color: #c69736;
  font-size: 22px;
  font-family: monospace;
  padding: 2px 5px;
  margin-left: 10px;
  border: none;
}
.main-price {
  font-size: 20px;
}
.highlight-text {
  color: #c69736;
}
.mrp-text {
  font-size: 16px;
  color: #666666;
}
.mrp {
  font-size: 16px;
  color: #666666;
}
.tax-text {
  font-size: 16px;
  color: #666666;
  padding-left: 10px;
}
.offer-price-text {
  color: #c69736;
}
.offer-price {
  color: #c69736;
}
.detail-section-inner h3 {
  color: #c69736;
  letter-spacing: normal;
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
}

.detail-page-customize {
  display: flex;
  color: #fff;
  width: 50%;
  border-top: 1px solid #c69736;
  padding: 10px 0;
  border-bottom: 1px solid #c69736;
  margin: 20px 0;
}

.detail-page-customize div {
  width: 100%;
  /* border-top: 2px solid gray; */
  /* border-bottom: 2px solid gray; */
  /* padding: 1rem; */
  margin-right: 0;
  /* margin-top: 1rem; */
}

.detail-page-button {
  display: flex;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  color: #fff;
}

.detail-page-button p {
  width: 50%;
  text-align: center;
  font-size: 1.5rem;
  margin: 0.8rem;
  font-weight: 600;
}

.detail-page-button button {
  width: auto;
  font-size: 18px;
  letter-spacing: 2px;
  background: #c69736;
  color: #fff;
  padding: 8px 15px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  font-family: "Poppins";
}

/* Detail Info */

.detail-info {
  /* height: 10vh; */
  border-top: 2px solid #c69736;
  border-bottom: 2px solid #c69736;
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  padding-left: 3rem;
  padding-right: 3rem;
}

.detail-info h2 {
  color: #c69736;
  width: 33%;
  font-weight: 500;
  padding: 10px 0;
  margin: 0;
  cursor: pointer;
}

/* Related products */

.related-products {
  /* height: 10vh; */
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.related-products div {
  width: 100%;
}

.related-products h1 {
  text-align: center;
  color: #c69736;
}

.just-border-2 {
  border-top: 0.3rem solid #c69736;
  height: auto;
  margin-top: 3rem;
  width: 20% !important;
}

.just-border-2 {
  border-top: 0.3rem solid #c69736;
  height: auto;
  margin-top: 2.8rem;
  width: 15% !important;
  position: relative;
  left: 24%;
}

.just-border-3 {
  border-top: 0.3rem solid #c69736;
  height: auto;
  margin-top: 2.8rem;
  width: 15% !important;
  position: relative;
  right: 24%;
}
select option {
  padding: 10px 20px;
  color: white;
  background-color: black;
}
.detail-img1 {
  display: inline;
}
.detail-img2 {
  display: none;
  height: auto;
}
.detail-info h2:nth-child(4) {
  display: none;
}
.detail-img-left {
  display: flex;
}
@media only screen and (max-width: 600px) {
  .detail-section {
    flex-direction: column;
    width: 100%;
  }
  .detail-img1,
  .detail-img-left {
    display: none;
  }
  .detail-img2 {
    display: block;
  }
  .detail-section-logo {
    width: 100%;
    margin-top: 1rem;
    height: auto;
  }
  .detail-section-text {
    padding: 0;
  }
  .detail-section-inner {
    margin-top: 0;
    margin: 1rem;
  }
  .detail-page-button p {
    width: 41%;
  }
  .detail-info {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    font-size: 10px;
  }
  .details-desc {
    margin: 0;
    padding: 20px;
    text-align: center;
  }
  .related-products {
    font-size: 0.7rem;
  }
  .just-border-2 {
    left: 9%;
    margin-top: 1.8rem;
  }
  .just-border-3 {
    right: 9%;
    margin-top: 1.8rem;
  }
  .detail-page-button p {
    font-size: 1.4rem;
  }
  .reviews {
    width: -webkit-max-content;
    width: max-content;
  }
  .star-ratings {
    width: 180px;
  }
  .detail-page-customize {
    width: auto;
  }
  .detail-info h2 {
    padding: 10px;
  }
  .detail-section-inner h3 {
    text-align: center;
  }
  .detail-section-inner h4 {
    text-align: center;
  }
  .detail-section-inner p {
    text-align: center;
  }
  .detail-page-button button {
    width: 100%;
  }
  .detail-section{
    left: 0%;
    transform: translate(0%);
  }
}

.reviews {
  display: flex;
  flex-direction: column;
  background-color: #242424;
  padding: 20px;
}
.review {
  width: 100%;
  margin-top: 20px;
}
.review-name {
  display: flex;
  margin-top: 2rem;
}
.review-name img {
  width: 75px !important;
  height: 75px;
  border-radius: 50%;
}
.review-name p {
  margin-left: 20px;
}
.star-ratings {
  width: 130px;
  margin-top: 1rem;
}
.review-name span {
  justify-content: center;
  align-items: center;
  color: white;
}
.reviews-para p {
  margin: 0px;
  font-weight: 700;

  color: white;
  flex-grow: 2;
}

.owl-themes {
  font-family: "Poppins", sans-serif;
  width: 680px !important;
  height: auto;
  padding: 0px 00px !important;
  margin: 0px !important;
}

.owl-theme .owl-nav [class*="owl-"] {
  font-size: 3em;
  margin: 0px;
}
.nav-btn {
  font-size: 3em;
}
.owl-dots {
  position: absolute;
  top: 95%;
  left: 45%;
}
.owl-dots button span {
  width: 10px !important;
  height: 10px !important;
  display: none;
}
.owl-stage-outer {
  position: relative !important;
}
.owl-nav {
  margin-top: 0px !important;
}
@media only screen and (max-width: 600px) {
  .owl-dots{
    position: relative !important;
    left: 0 !important;
    bottom: -15px;
  }
}
.contact-back{
    background-image: url('https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-modern-double-color-futuristic-neon-background-image_351866.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding-bottom: 30px;
}
.contact-cont{
   border: 3px solid goldenrod;
   margin: 30px;
   background-color: black;
   padding: 20px;
}
.contact-logo{
   background-color: black !important;
   padding: 5px 25% !important;
}
.contact-logo hr{
   background-color: goldenrod;
   border: none !important;
}
.contact-head{
   color:goldenrod ;
   text-transform: uppercase;
   text-align: center;
   font-weight: 1000;
}
.contact-para{
   text-align: center;
   color: white;
   font-weight: 800;
   font-size: 1.1em;
   padding: 0px 25%;
   letter-spacing: 1px;
   
}

.contact-logo p{
   letter-spacing: normal;
   color: goldenrod;
   margin: 0px 10px;
}
.contact-details-cont{
display: grid;
grid-template-columns: auto auto auto;
}
.contact-detail{
   border: 2px solid goldenrod;
   text-align: center;
   margin: 20px;
}
.contact-detail img{
width: 100%;
height: 300px;
}
.contact-p1{
   color: goldenrod;
   font-size: 1.5em;
   font-weight: 1000;

}
.contact-p2{
   color: white;
   font-weight: 500;
   word-spacing: 3px;
   letter-spacing: normal;
}
.contact-p3{
   color: goldenrod;
   word-spacing: 3px;
   letter-spacing: normal;
}

.corp-contact-details-cont {
    display: flex;
    grid-template-columns: auto auto auto;
}

.corp-contact {
    border: 2px solid goldenrod;
    text-align: center;
    margin: 20px;
    width: 100%;
}

.corp-contact img {
    width: 100%;
    height: 400px;
}

@media only screen and (max-width: 600px) {
.contact-details-cont{
   display: block;
}
.contact-para{
   padding: 0;
   font-weight: 400;
   font-size: 15px;
}
.contact-logo p{
   font-size: 25px;
}
.contact-logo{
   padding: 5% !important;
}
.contact-detail{
      margin: 20px 0;
      word-break: break-all;
 }
 .contact-p1{
    padding: 0px 10px;
 }
 .contact-p2{
   padding: 0px 10px;
}
 .contact-p3{
   padding: 0px 10px;
}

.corp-contact-details-cont {
    flex-direction: column;
    align-items: center;
}
}



.forhim-top-section {
  height: auto;
  background: url(/static/media/category-banner.93c388ab.jpg);
  background-position: center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 1);
  border-top: 2px solid #fff;
  border-bottom: 2px solid #c69736;
  font-family: "Poppins", sans-serif;
}

.forhim-inner-section {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.forhim-inner-section h1 {
  color: #c69736;
  text-transform: uppercase;
  font-size: 40px;
  font-family: 'Gill Sans W04 Roman', Calibri, sans-serif;
  letter-spacing: 1px;
}

.forhim-inner-section p {
  color: #fff;
  padding-left: 20%;
  padding-right: 20%;
  line-height: 2rem;
}

.filter-section {
  height: 10vh;
  border-bottom: 2px solid #c69736;
}

.lower-menu {
  height: 8vh;
  border-top: 0px solid #c69736;
  border-bottom: 1px solid #c69736;
  color: #c69736;
}

.lower-menu ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  padding-top: 0.5rem;
}

.lower-filters {
  height: 10vh;
  border-bottom: 2px solid #c69736;
}

.products-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.load-more {
  height: 12vh;
  border-top: 1px solid #c69736;
  border-bottom: 2px solid #c69736;
}

.load-more button {
  padding: 0.7rem;
  color: #c69736;
  background: transparent;
  border: 2px solid #c69736;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: 0.1rem;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
}

.filters {
  position: relative;
  top: 1rem;
  cursor: pointer;
  font-style: normal;
}
.filters i {
  font-size: 11px;
    padding-left: 5px;
}

@media only screen and (max-width: 600px) {
  .forhim-inner-section p{
    padding-left: 10px;
    padding-right: 10px;
  }
  .lower-menu{
    height: 6vh;
  }
  .lower-menu ul{
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    left: 0;
    transform: none;
    padding-top: 0.5rem;
  }
}



.text_rtl {
    direction: rtl;
}
.text_rtl {
    direction: rtl;
}
.founder-container-box{
  text-align: center;
    padding: 50px 0 0;
}
.founder-container-box img{
  width: 40%;
  border: 5px solid #c69736;
}
.company-side img{
  float: right;
}
.founder-title{
  margin: 8px;
  font-size: 25px;
  font-weight: bold;
  color: #c69736;
}
.founder-designation{
margin: 0;
font-family: 'Poppins';
font-size: 15px;
font-weight: bold;
color: #000;
}
.aboutus-container {
    background-image: url(/static/media/glitter7.715670c5.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 140px;
    display: flex;
    flex-direction: column;
  }

  .aboutus-head h1 {
    font-family: "Gill Sans W04 Roman", Calibri, sans-serif;
    font-size: 4rem;
    padding: 0px 25%;
    font-weight: bold;
    line-height: 1em;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    letter-spacing: 0.06667em;
    text-transform: uppercase;
    color: #000;
  }
  
  .aboutus-head {
    padding: 1.25em 0;
    background: #fff;
    mix-blend-mode: lighten;
  }
  
  .aboutus-head::after {
    display: table;
    clear: both;
    content: "";
  }
  
  .aboutus-container-box {
    display: flex;
    flex-direction: column;
    padding: 80px;
    padding-top: 0px;
    background-color: white;
  }
  
  .aboutus-logo hr,
  .aboutus-logo img {
    display: inline;
  }
  
  .aboutus-logo {
    display: flex;
    align-items: center;
    padding: 0px 7%;
    background-color: white;
    padding-top: 50px;
  }
  
  .aboutus-logo hr {
    width: 100%;
    height: 1px;
    border: none;
    border-top: 1px solid rgb(167, 167, 167);
    justify-content: center;
  }
  
  .aboutus-logo img {
    width: 160px;
    height: 75px;
    margin: 0px 20px;
  }
  
  .aboutus-logo hr,
  .aboutus-logo img {
    display: inline;
  }
  
  .aboutus-logos {
    display: flex;
    align-items: center;
    padding: 0px ;
    background-color: white;
    
  }
  .aboutus-logos p{
      flex-grow: 3;
      width: 100%;
      font-size: 1.1em;
      font-weight: 1000;
      color: #a87e3b;
      text-align: center;
      letter-spacing: 5px;
  }
  
  .aboutus-logos hr {
    width: 100%;
    height: 1px;
    border: none;
    border-top: 1px solid rgb(167, 167, 167);
    justify-content: center;
  }

  .about-para {
    color: #555;
    padding: 20px 5%;
    text-align: center;
    font-weight: 800;
    font-size: 1.1em;
    line-height: 30px;
  }
  
  .aboutus-form form {
    display: flex;
    flex-direction: column;
    padding: 30px 25%;
  }
  
  .aboutus-form form input {
    padding: 12px 16px;
  }
  
  .aboutus-form form label {
    font-size: 0.8em;
    margin: 10px 0px;
  }
  
  .sub-button {
    margin: 10px 20%;
    color: white;
    background: #6d4d15;
    border: none;
    font-size: 0.8em;
  }
  
  .about-us-decor {
    display: flex;
    padding-top: 60px;
    justify-content: center;
  }
  
  .about-us-decor div {
    width: 100%;
  }
  
  .about-us-decor div img {
    height: 400px;
    width: 400px;
    margin: 0px;
    border: 5px solid #c69736;
  }
  
  .about-us-decor div h5 {
    text-transform: uppercase;
    color: #c69736;
    font-size: 30px;
    letter-spacing: 0;
    text-align: left;
    margin: 0;
  }
  
  .about-us-decor div p {
    color: #000;
    text-align: justify;
    font-family: 'Poppins';
    font-weight: 1000;
    letter-spacing: 0.5px;
    line-height: 30px;
  }
  
.term-condition-cont table{
text-align: center;
align-items: flex-start;
}

.term-condition-cont table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .term-condition-cont table th, .term-condition-cont table td {
    text-align: left;
    padding: 8px;
  }
  
  .term-condition-cont table tr:nth-child(odd) {background-color: #f2f2f2;}

  .term-cond b{
    font-family: 'Merriweather', serif;
    font-size: .8125em;
    font-weight: 600;
    line-height: 2.5;
    letter-spacing: .04em;
    color: #555;
  }
    .aboutus-container-box{
    background-color: white;
  }
  .term-ul li{
    padding-bottom: 15px;
  }
  .term-ol li{
    padding-bottom: 15px;
  }
  @media only screen and (max-width: 900px) {
    .about-us-decor div img {
      height: 250px;
      width: 100%;
      margin: 20px;
    }
    .founder-container-box{
      padding: 5px 0 0;
    }
    .founder-container-box img {
      width: 90%;
    }
    .about-us-decor {
      flex-direction: column;
      padding-top: 25px;
    }
    .aboutus-logo {
      padding: 20px;
    }
    .aboutus-head h1 {
      padding: 10px;
      font-size: 2.5em;
    }
    .about-para {
      padding: 20px;
    }
    .aboutus-form form {
      padding: 30px;
    }
    .abooutus-images div:nth-child(2){
  order: 1;
    }
    .abooutus-images div:nth-child(1){
  order: 2;
  
    }
  }
  
  @media only screen and (max-width: 600px) {
      .aboutus-container {
      padding: 20px;
      }
  .aboutus-container-box {
  padding: 10px;
  }
  .about-us-decor div p{
    padding: 0 10px;
  }
  .about-us-decor div h5{
    padding: 0 10px;
  }
  .about-us-decor div img {
      height: 250px;
      width: 90%;
      margin: 10px;
    }
  }

.text_rtl {
    direction: rtl;
}

sub-li {
    content: counters(item, ".") " ";
    counter-increment: item
}

.contact_us {
    color: #c69736;
    font-size: 20px;
    cursor: pointer;
}
.text_rtl {
    direction: rtl;
}

.shipping-policy-table {
    border: 2px solid #C69736;
    display: block;
    color: #fff;
    padding: 15px 35px;
    text-align: left;
    border-collapse: collapse;
    width: 100%;
}

.shipping-policy-table tr td {
    color: black;
}
.carts-box {
  display: flex;
  flex-direction: column;
  width: 65%;
  border: 3px solid #c69736;
  padding: 20px;
  position: relative;
  margin: 20px;
  height: auto; /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
  transition: all 1s ease;
}
.price-strike{
  display: none;
}
.carts-box h1 {
  color: #c69736;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 0;
}

.carts-imgbox img {
  width: 200px;
  height: 200px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.carts-quant-box {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.subtotals-price {
  color: white;
}
.ccartprice{
  margin: 0;
  font-size: 18px !important;
}
.ccartsize{
  margin: 8px;
}
.carts-detail-heading {
  color: gold;
}
.subtotals-price {
  display: flex;
  justify-content: center;
}
.remove {
  border-radius: 50%;
  background-color: gray;
  position: absolute;
  padding: 2px 7px;
  top: -10px;
  right: -8px;
  color: #fff;
  cursor: pointer;
}

.carts-box:hover {
  transform: scale(1.05);
}

.qty-switch {
    cursor: pointer;
    max-width: 24px;
}

.qty-text {
    color: #fff;
    padding: 0px 5px 0px 5px;
    font-size: 15px;
}

.ccartqty {
    display: flex;
    margin: 8px;
    justify-content: center;
    align-items: center;
}

.checkout-box {
  margin-top: 30px;
  background: #2b2b2b;
  width: 90%;
  color: white;
  border: 1px solid #d49b0b;
}
.checkout-box1 {
  padding: 0.5rem 1.5rem;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
}

.checkout-summary {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.checkout-summary-head {
  border-bottom: 1px solid white;
}

.checkout-summary-head h1 {
  font-weight: 500;
  margin: 0.8rem;
  margin-left: 0;
  text-transform: uppercase;
  color: #b98d32;
}

.checkout-box2 {
  padding: 1.5rem 1.5rem;
}
.shipping {
  border-bottom: 1px solid white;
}
.subtotal,
.shipping,
.ordertotal {
  display: flex;
}
.subtotal div:last-child,
.shipping div:last-child,
.ordertotal div:last-child {
  text-align: right;
}
.subtotal div,
.shipping div,
.ordertotal div {
  width: 100%;
  padding-bottom: 1.5rem;
}
.ordertotal div {
  font-size: 1.5em;
  font-weight: 500;
  padding: 10px 0;
}
.checkout-box3 {
  background: black;
  padding: 20px 50px;
  text-align: center;
  
}
.checkout-box3 button {
  padding: 1rem 2rem;
  border: 1px solid white;
  color: #000;
  background: #c69736;
  text-transform: uppercase;
  font-size: 18px;
  width: 100%;
  font-weight: 500;
}

.coupon-area p {
  color: #fff;
  margin-left: 2.5rem;
}

.coupon-area {
  width: 100%;
  border-bottom: 1px solid #c69736;
  padding-right: 50px;
  text-align: right;
}

.coupon-area input {
  padding: 0.5rem;
  width: 20%;
  border: none;
  margin-bottom: 2rem;
  margin-left: 2.5rem;
}

.coupon-area button {
  padding: 0.5rem;
  background: #c69736;
  color: #fff;
  border: none;
}

.cartscreen {
  font-family: "Poppins", sans-serif;
}
.cartcard-holder {
  display: grid;
  grid-template-columns: auto auto auto;
  width: 65%;
}

.cart-screen {
  display: flex;
  flex-wrap: wrap;
}

.checkkout-box {
  width: 35%;
}

@media only screen and (max-width: 600px) {
  .cart-screen {
    flex-direction: column;
  }
  .cart-screen div {
    height: auto !important;
  }

  .cartcard-holder {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .checkkout-box {
    width: auto;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .carts-box {
    width: 100%;
    margin: 20px 30px;
  }
  .checkout-box{
    width: auto;
  }
  .coupon-area{
    width: auto;
    padding-right: 2rem;
  }
  .coupon-area input{
    width: auto;
  }
  .checkout-box3{
    padding: 20px;
  }
}

.checkout-page {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  border-bottom: 1px solid #c69736;
}

/* .checkout-page input {
  border-radius: 0.4rem;
} */

.top-info {
  width: 100%;
  height: 6vh;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #c69736;
  color: #c69736;
}

.top-info h3 {
  font-weight: 400;
  margin: 0.4rem;
}

.left-info {
  width: 60%;
}

.top-logo {
  width: 100%;
  height: auto;
}

.top-logo img {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 250px;
  height: 200px;
  margin-top: 2rem;
}

.contact-info {
  height: auto;
  width: 100%;
  margin-top: 1rem;
}

.contact-info p {
  display: inline;
  padding: 3rem;
  padding-left: 4.7rem;
  font-size: 18px;
}

.just-width {
  margin-inline: 5%;
}

.contact-info input {
  width: 75%;
  padding: 1rem;
  margin: 3rem;
  margin-left: 5rem;
}

.lower-info h2 {
  padding-left: 3rem;
  margin: 0;
}

.just-border-4 {
  width: 80%;
  border-top: 1px solid #fff;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.right-info {
  height: 150vh;
  width: 40%;
  background: rgb(56, 56, 56);
}

.first-right-div {
  display: flex;

  flex-wrap: wrap;
}

.inner-section-info div {
  border-bottom: 1px solid gray;
}

.first-right-div img {
  height: 100px;
  width: 100px;
}

.second-right-div {
  padding: 2rem;
}

.second-right-div input,
button {
  padding: 1rem;
}

.second-right-div input {
  width: 60%;
}

.second-right-div button {
  width: 25%;
  margin-left: 1rem;
  color: #fff;
  background: gray;
}

.inner-section-info {
  padding: 1rem;
  margin: 3rem;
}

.select-country {
    width: 100%;
    background: transparent;
    color: #6f6f6f;
    font-size: 14px;
    border: none;
    min-height: 4vh;
}

.select-country:hover {
    cursor: pointer;
}

.country-div {
    width: 102%;
    min-width: 102%;
    background: transparent;
    border: 1px solid #c69736;
    color: #bababa;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.country-text {
    color: #bababa;
    font-size: 20px;
}

.checkout-form-input{
    width: 10px;
}
.shipping-page {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  border-bottom: 1px solid #c69736;
}

.shipping-top-info {
  width: 100%;
  height: 6vh;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #c69736;
  color: #c69736;
}

.shipping-top-info h3 {
  font-weight: 400;
  margin: 0.4rem;
  padding-left: 2rem;
}

.shipping-left-info {
  height: auto;

  width: 60%;
}

.shipping-right-info {
  height: auto;
  width: 40%;
}

.right-info-2 {
  position: -webkit-sticky;
  position: sticky;
  height: 100vh;
  top: 0;
  width: 40%;
  background: rgb(56, 56, 56);
}

.first-right-div {
  display: flex;
  justify-content: space-between;
}

.inner-section-info div {
  border-bottom: 1px solid gray;
}

.first-right-div img {
  height: 100px;
  width: 100px;
}

.second-right-div {
  padding: 2rem;
}

.second-right-div input,
button {
  padding: 1rem;
}

.second-right-div input {
  width: 60%;
}

.second-right-div button {
  width: 25%;
  margin-left: 1rem;
  color: #fff;
  background: gray;
}

.inner-section-info {
  padding: 1rem;
  margin: 3rem;
}

.top-logo {
  width: 100%;
  height: auto;
}

.top-logo img {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 250px;
  height: 200px;
  margin-top: 2rem;
}

.bottom-section {
  display: flex;
  flex-direction: column;
  margin: 7rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.bottom-section section {
  background: transparent;
    border: 1px solid #c69736;
    color: #fff;
    padding: 1rem;
    margin-bottom: 20px;
    grid-gap: 15px;
    gap: 15px;
    border-radius: 0;
}

.shipping-email{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inner-section {
  display: flex;
  justify-content: space-between;
}

.last-section {
  margin: 0 7rem;
  margin-bottom: 0px;
}

.icon {
  display: block;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  margin: 0 auto;
}

.icon:before,
.icon:after {
  content: "";
  position: absolute;
}

.dropp {
  width: 100%;
  box-shadow: 0 1px 3px #0a0a0a;
}

.dropp-header {
  background: #242424;
  color: #fff;
  border-bottom: 2px solid #c69736;
  box-align: stretch;
  -moz-align-items: stretch;
  -ms-align-items: stretch;
  -o-align-items: stretch;
  align-items: stretch;
  -ms-flex-align: stretch;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -moz-flex;
  display: flex;
  box-orient: horizontal;
  box-direction: normal;
  -moz-flex-direction: row;
  flex-direction: row;
  box-pack: start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  -o-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: start;
}

.dropp-header__title {
  display: block;
  padding: 0.8em 0.5em;
  box-flex: 8;
  -moz-flex: 8;
  flex: 8 1;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.dropp-header__btn {
  display: block;
  background: #1c1c1c;
  color: #fff;
  padding: 0.8em 0.5em;
  box-flex: 1;
  -moz-flex: 1;
  flex: 1 1;
  transition: all 0.3s ease-in-out;
}

.dropp-header__btn .icon {
  transform: rotate(-90deg);
}

.dropp-header__btn .icon:before,
.dropp-header__btn .icon:after {
  top: 30%;
  left: 25%;
  width: 50%;
  height: 15%;
  background: #c69736;
  transform: rotate(-45deg);
}

.dropp-header__btn .icon:after {
  top: 55%;
  transform: rotate(45deg);
}

.dropp-header__btn.js-open {
  background: #c69736;
}

.dropp-header__btn.js-open .icon {
  transform: rotate(90deg);
}

.dropp-header__btn.js-open .icon:before,
.dropp-header__btn.js-open .icon:after {
  background: #fff;
}

.dropp-header__btn:focus {
  outline: none;
}

.dropp-body {
  overflow: hidden;
  width: 100%;
  max-height: 0;
  background: #292929;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.dropp-body.js-open {
  max-height: 20em;
}

.dropp-body label {
  display: block;
  font-size: 0.875em;
  color: #fff;
  text-decoration: none;
  padding: 1em 0.5em;
  font-weight: 400;
  box-shadow: 0 -1px 0 #171717, inset 0 1px 0 #313131;
  cursor: pointer;
}

.dropp-body label:first-child {
  box-shadow: none;
}

.dropp-body label:hover,
.dropp-body label.js-open {
  background: #c69736;
}

.dropp-body label > input {
  display: none;
}

.shipping-heading{
   color: #c69736;
    text-transform: uppercase;
    font-size: 16px;
}
@media only screen and (max-width: 600px) {
  .right-info-2{
    width: 100%;
    height: auto;
    order: 1;
    position: relative;
  }
  .shipping-top-info{
    height: auto;
  }
  .shipping-left-info{
    width: 100%;
    order: 2;
  }
  .bottom-section{
    margin: 0 20px;
  }
  .last-section{
    margin: 20px;
  }
  .last-section span{
    display: inline-block;
  }
}
.payment-page {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  border-bottom: 1px solid #c69736;
  height: auto;
}

.payment-left-info {
  height: auto;
  width: 60%;
}

@media only screen and (max-width: 600px) {
  .payment-left-info{
    width: 100%;
    order: 2;
  }
}

.payment-email{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.reviewcard{
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #c69736;
}
.reviewcard .cardd{    
    width: 100%;
    display: flex;
    margin: 2rem;
    height: auto;
}
.reviewcardbox{
    border: 1px solid #c69736;
    padding: 20px;
    text-align: center;
}
.reviewcardbox-img{
    display: initial;
}
.reviewcardbox-title{
    color: #c69736;
    font-size: 18px;
}
.reviewcardbox-price{
    font-weight: 500;
    margin: 0;
    padding: 10px 0 0 0;
    color: #fff;
}
.reviewcardbox-input{
    padding: 4px 10px;
    height: 40px;
    background: transparent;
    border: 1px solid #C69737;
    color: #fff;
}
.reviewcardbox-btn{
    padding: 4px 10px;
    background: transparent;
    border: 1px solid #C69737;
    color: #fff;
    display: -webkit-inline-box;
    height: 50px;
}
@media only screen and (max-width: 600px) {
    .reviewcard .cardd{
        display: block;
    }
}

.rate {
    float: none;
    height: 46px;
    padding: 0 0 5px 0px;
    text-align: center;
    display: inline-flex;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}

/* Modified from: https://github.com/mukulkant/Star-rating-using-pure-css */
.order-top-section1 {
  width: auto;
  position: relative;
  text-align: center;
  height: auto;
  margin-bottom: 30px;
}

.order-top-section1 .order-top-logo img {
  width: 300px;
  height: 200px;
  position: relative;
  left: auto;
  transform: none;
}

.order-top-section1 h1 {
  color: #c69736;
  text-align: center;
  letter-spacing: 0.3rem;
}

.order-top-section1 button {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  background: #c69736;
  color: #fff;
  border: none;
  border-radius: 0.2rem;
}

.order-top-section1 p {
  color: #fff;
  text-align: center;
}

.order-middle-section {
  display: flex;
  width: 80%;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.order-middle-section div {
  border: 1px solid #c69736;
  height: auto;
  width: 100%;
  background: rgb(56, 56, 56);
}

.order-middle-section-new {
    border: 0.25px solid #333333;
    display: flex;
    width: 80%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}

.order-middle-section-new div {
    padding: 2%;
    height: auto;
    width: 100%;
}

.order-table-container {
    border: 0.25px solid #333333;
    display: flex;
    width: 80%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    margin-top: 15px;
}

.order-table-new {
    border: 2px solid #C69736;
    display: block;
    color: #fff;
    padding: 15px 35px;
    text-align: left;
    border-collapse: collapse;
    width: 100%;
}

.order-table-new tr {
    border-bottom: 1px solid grey;
}

.seller_address {
    display: flex;
    width: 80%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    border: 0px solid #c69736;
    height: auto;
    color: white;
}

.order-middle-section p {
  color: #c69736;
  padding-left: 1rem;
  font-weight: 600;
}

.order-middle-section div h1 {
  color: #c69736;
  padding-left: 2rem;
}

.order-id p {
  display: inline;
}

.order-date p {
  display: inline;
}

.order-total p {
  display: inline;
}

.order-middle-section section {
  padding: 1rem;
}

.cart-confirmation {
  width: 80%;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  
  flex-direction: column;
  border: 2px solid #c69736;
  margin-top: 3rem;
}

.cart-confirmation p {
  color: #c69736;
  font-weight: bold;
  font-size: 1.2rem;
}

.top-shelf1 {
  display: flex;
  justify-content: space-between;

  align-items: center;
  margin-left: 4rem;
  padding-right: 10rem;
  border-bottom: 2px solid gray;
  margin-right: 4rem;
}

.middle-shelf {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 4rem;
  margin-right: 4rem;
  border-bottom: 2px solid gray;
}

.middle-shelf section {
  display: flex;
}

.middle-shelf img {
  height: 100px;
  width: 100px;
}

.lower-shelf {
  width: 30%;
  position: relative;
  left: 65%;
  height: auto;
}
.orderbtn{
  padding: 10px;
  background: #c69736;
}
.checkout-page {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  border-bottom: 1px solid #c69736;
}

.checkout-page input {
  border-radius: 0;
  background: transparent;
  border: 1px solid #c69736;
  width: 100%;
  color: #fff;
  margin: 10px 15px 10px 0;
}
.continue-btn{
  padding: 1rem;
    margin: 1rem !important;
    margin-left: 0 !important;
    color: #000 !important;
    background-color: #c69736 !important;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    border: 0;
}
.address-box{
  padding: 5px 108px 35px 50px;
}
.saved-address{
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 1px;
  color: #c69736;
  font-weight: 500;
  margin-bottom: 25px;
}
.select-dot{
  float: left;
}
.select-dot input{
  padding: 0 !important;
  margin-top: 4px;
}
.contact-info input:active{
  border-radius: 0;
}
.top-info {
  width: 100%;
  height: 6vh;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #c69736;
  color: #c69736;
}

.top-info h3 {
  font-weight: 400;
  margin: 0.4rem;
  padding-left: 2rem;
}

.left-info {
  width: 60%;
}

.top-logo {
  width: 100%;
  height: auto;
}

.top-logo img {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 250px;
  height: 200px;
  margin-top: 2rem;
}

.contact-info {
  height: auto;
    width: 100%;
    margin-top: 1rem;
    padding: 0 50px;
}

.contact-info p {
  display: inline;
  padding: 0;
  padding-left: 0;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 1px;
  color: #c69736;
}

.just-width {
  margin-inline: 5%;
}

.contact-info input {
  width: 76%;
  padding: 1rem;
  margin: 1rem 0;
  margin-left: 0;
}
.lower-info{
  padding: 0 50px;
    margin-top: 15px;
}
.checkout-form{
  padding: 0 50px;
  padding-right: 0px;
  width: 79%;
}
.lower-info h2 {
  margin: 0;
  font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #c69736;
    font-weight: 500;
    margin-bottom: 10px !important;
    text-align: left;
}

.just-border-4 {
  width: 80%;
  border-top: 1px solid #fff;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.right-info {
  height: 150vh;
  width: 40%;
  background: rgb(56, 56, 56);
}

.first-right-div {
  display: flex;
  justify-content: flex-start;
}
.rightsideinfo-div{
  width: 49%;
  border-bottom: none !important;
}
.inner-section-info div {
  border-bottom: 1px solid gray;
}

.first-right-div img {
  height: 100px;
  width: 100px;
  border: 2px solid #c69736;
}
.rightsideinfo-total{
  font-weight: 600;
    font-size: 24px;
    display: inline-flex;
    padding-bottom: 0;
    margin: 0;
    margin-bottom: 0;
}
.total-box{
  margin: 15px 0;
}
.rightsideinfo-tax{
  width: 60%;
  padding-top: 0;
  margin-top: 0;
}
.rightsideinfo-tax{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.rightsideinfo-totalprice{
  margin: 0;
    display: inline-flex;
    float: right;
}
.item-name{
  font-family: auto;
  color: #c69736;
  margin: 8px 0;
  letter-spacing: 1px !important;
}
.item-price {
  margin: 0;
  margin-bottom: 10px;
}
.item-number{
  background: #c69736;
  padding: 0px 5px;
  border-radius: 50px;
  position: absolute;
  margin-left: -8px;
  margin-top: -8px;
}
.second-right-div {
  padding: 2rem;
}

.second-right-div input,
button {
  padding: 1rem;
}

.second-right-div input {
  width: 60%;
}

.second-right-div button {
  width: 25%;
  margin-left: 1rem;
  color: #fff;
  background: gray;
}

.inner-section-info {
  padding: 1rem;
  margin: 3rem;
}

@media only screen and (max-width: 600px) {
  .left-info{
    width: auto;
    order: 2;
  }
  .contact-info{
    width: auto;
  }
  .checkout-page input{
    width: 90% !important;
  }
  .checkout-page input[type="checkbox"]{
    width: auto !important;
  }
  .address-box {
    padding: 15px 50px;
  }
  .top-info{
    height: auto;
  }
  .continue-btn{
    width: 100%;
    font-size: 15px;
  }
  .inner-section-info {
    padding: 1rem;
    margin: 1rem;
  }
  .checkout-form{
    width: 96%;
    margin: auto;
    padding: 0px;
  }
}
.row a:hover {
    font-weight: bold;
}

.welcome-user-name.track-order-heading{
    color: #c69736;
    font-weight: 500;
    padding: 0px 20px;;
    text-transform: uppercase;
}
.address-book div{
    border-top: 1px solid #c69736;
    border-bottom: 1px solid #c69736;
    padding: 10px 20px;
}
.address-book div:hover{
    background: #c69736;
    color: #000;
}
.order-top-section {
    width: 60%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    height: auto;
  }
  .activeclass{
    background: #c69736;
  }
  
  .order-top-logo img {
    width: 300px;
    height: 200px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
  
  .order-top-section h1 {
    color: #c69736;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.3rem;
  }
  
  .order-top-section button {
    position: relative;
    left: 50%;
    transform: translate(-50%);
    background: #c69736;
    color: #000;
    border: none;
    border-radius: 0.2rem;
  }
  
  .order-top-section p {
    color: #fff;
    text-align: center;
  }
  
  .order-middle-section {
    display: flex;
    width: 80%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
  
  .order-middle-section div {
    border: 2px solid #c69736;
    height: auto;
    width: 100%;
    background: rgb(56, 56, 56);
  }
  
  .order-middle-section p {
    color: #c69736;
    padding-left: 1rem;
    font-weight: 600;
  }
  
  .order-middle-section div h1 {
    color: #c69736;
    padding-left: 2rem;
    font-weight: 400;
  }
  
  .order-id p {
    display: inline;
  }
  
  .order-date p {
    display: inline;
  }
  
  .order-total p {
    display: inline;
  }
  
  .order-middle-section section {
    padding: 1rem;
  }
  
  .cart-confirmation {
    width: 80%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    border: 2px solid #c69736;
    margin-top: 3rem;
  }
  
  .cart-confirmation p {
    color: #c69736;
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  .top-shelf {
    display: flex;
    justify-content: space-between;
  
    align-items: center;
    margin-left: 4rem;
    margin-right: 4rem;
    border-bottom: 2px solid gray;
  }
  
  .middle-shelf {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 4rem;
    margin-right: 4rem;
    border-bottom: 2px solid gray;
  }
  
  .middle-shelf section {
    display: flex;
    width: 25%;
  }

  .middle-shelf h2 {
    font-size: 1rem;
    padding-top: 2px;
    padding-left: 0.5rem;
  }

  .middle-shelf p {
    color: #fff !important;
    padding-top: 1.3rem;
    margin-left: 2rem;
  }
  
  .middle-shelf img {
    height: 100px;
    width: 100px;
  }
  
  .lower-shelf {
    width: 30%;
    position: relative;
    left: 65%;
    height: auto;
  }
  .order-table{
    border: 2px solid #C69736;
    display: block;
    color: #fff;
    padding: 15px 35px;
    text-align: left;
    border-collapse: collapse;
    margin: 20px 135px;
  }
  .order-table-th th{
    color: #c69736;
    padding: 20px 0;
    font-weight: bold;
    font-size: 1.2rem;
  }
  .order-table-th th:nth-child(1){
    width: 50%;
  }
  .order-table-th th:nth-child(2){
    width: 30%;
  }
  .order-table-th th:nth-child(3){
    width: 30%;
  }
  .order-table-th th:nth-child(4){
    width: 30%;
  }
  .order-table-img a img{
    width: 100px;
    height: 100px;
  }
  .order-table-img h2{
    font-weight: 300;
    margin: 0;
    font-weight: 500;
    display: inherit;
    color: #c69736 !important;
    vertical-align: inherit;
    font-size: 20px;
  }
  .order-table-img a{
    display: inherit;
  }
  .order-table tr{
    border-bottom: 1px solid grey;
  }
  .text-right{
    text-align: right;
  }



  .myorder-right {
    width: 68%;
    display: inline-block;
    padding: 50px 0;
    text-align: center;
    margin: 0 80px;
}
  .myorder-ulbar{
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    list-style: none;
    background: transparent;
    padding: 0;
    margin: 0 0 40px 0;
  }
  .myorder-ulbar li{
    width: 100%;
    border: 2px solid #c69736;
    padding: 15px;
  }
  .below-row td{
    padding: 10px 0;
  }
  .row-heading{
    color: #c69736;
    padding: 20px 0;
    font-weight: bold;
    font-size: 1.2rem;
  }
  .below-row{
    border-bottom: none !important;
  }
  .order-right{
    width: 77%;
    display: inline-block;
    padding: 50px 0;
    text-align: center;
  }
  .myorder-table {
    border: 2px solid #C69736;
    display: block;
    color: #fff;
    padding: 15px 35px;
    text-align: left;
    border-collapse: collapse;
    margin: 20px 0;
}

.myorder-table-th th{
  color: #c69736;
  padding: 20px 0;
  font-weight: bold;
  font-size: 1.2rem;
}
.myorder-table-th th:nth-child(1){
  width: 50%;
}
.myorder-table-th th:nth-child(2){
  width: 15%;
}
.myorder-table-th th:nth-child(3){
  width: 10%;
}
.myorder-table-th th:nth-child(4){
  width: 5%;
}
.myorder-table-img a img{
  width: 100px;
  height: 100px;
}
.myorder-table-img h2{
  font-weight: 300;
  margin: 0;
  font-weight: 500;
  display: inherit;
  color: #c69736 !important;
  vertical-align: inherit;
  font-size: 20px;
}
.myorder-table-img a{
  display: inherit;
}
.myorder-table tr{
  border-bottom: 1px solid grey;
}
  @media only screen and (max-width: 600px) {
    .payment-left-info{
      width: 100%;
      order: 2;
    }
    .order-top-section{
      width: auto;
    left: 0;
    padding: 0 20px;
    transform: none;
    }
    .order-middle-section{
      width: auto;
      display: block;
    left: 0;
    margin-bottom: 30px;
    padding: 0 20px;
    transform: none;
    }
    .order-top-section button{
      margin-bottom: 15px;
    }
    .order-table{
      margin: 0 20px;
      padding: 0 10px;
    }
    .order-table-img a img {
      width: 80px;
      height: 80px;
  }
  .order-table-img h2{
    font-size:15px;
  }
  .myorder-right{
    margin: 0px 15px;
    width: auto;
  }
  .myorder-ulbar li{
    width: auto;
  }
  .myorder-table{
    padding: 15px 10px;
  }
  .myorder-table-img a img {
    width: 80px;
    height: 80px;
    padding-right: 5px;
}
.text-right{
  text-align: left;
}
.order-table tr{
  display: flex;
}
tr.order-table-tr td {
  width: 100%;
}
.myorder-table-img{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.price-tag{
  text-align: center;
}

tr.below-row{
  grid-gap: 20px;
  gap: 20px;
}
.myorder-right {
  margin: 0px;
  width: auto;
}
  }
.my-account {
  display: flex;
  flex-wrap: wrap;  
  border-bottom: 1px solid #c69736;
}

.two-buttons {

}
.two-buttons a {

}
.breadcrumb-css{
  color: #c69736;
  margin: 0;
  padding: 10px 40px;
  font-size: 15px;
  border-top: 1px solid #c69736;
  border-bottom: 1px solid #c69736;
}
.sidebar-left{
  width: 20%;
  display: inline-block;
  background-color: transparent;
    border-right: 1px solid #c69736;
}
.address-right{
  width: 77%;
  display: inline-block;
  padding: 50px 0;
  text-align: center;
}
.saved-address-div{
    border: 1px solid #c69736;
    color: white;
    margin: 0 90px;
    padding: 2rem;
    position: relative;
}
.heading-address{
  color: #fff;
  padding: 10px 0;
}
@media only screen and (max-width: 600px) {
  .address-right{
    width: auto;
  }
  .sidebar-left{
    width: 100%;
  }
  .saved-address-div{
    margin: 0 20px;
  }
}
.cartscreen {
  font-family: "Poppins", sans-serif;
}
.wishlist-cardd{
  width: 100%;
  display: flex;
}
@media only screen and (max-width: 600px) {
  .card{
  width: 50%;
  height: inherit;
  min-height: 50%;
  display: inline-block;
  vertical-align: top;
  }
  .wishlist-cardd {
    width: auto;
    display: block;
}
}
.cart-box {
  border: 1px solid #c69736;
  width: 85%;
  padding: 20px;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 30px;
  background: #000;
}

.head-cart {
  display: flex;
  border-bottom: 1px solid rgb(194, 194, 194);
  padding: 10px 0px;
  color: #c69736;
}

.head-cart span {
  width: 30%;
}

.head-cart span:first-child {
  width: 100%;
}

.cart-detail-heading {
  margin-left: 30px;
}

.cart-imgbox {
  display: flex;
  width: 100%;
  margin-top: -1rem;
}

.cart-quant-box {
  width: 100%;
}

.cart-imgbox img {
  width: 70px;
  height: 80px;
}

.cart-body {
  display: flex;
  padding: 20px;
}

.cart-details {
  display: flex;
  flex-direction: column;
  flex-grow: 5;
}

.cart-detail-head {
  display: flex;
}

.cart-detail-trash {
  flex-grow: 10;
}

.trash-btn {
  float: right;
}

.trash-btn:hover {
  color: black;
}

.cart-detail-heading h1 {
  color: #fff;
  font-size: 1.5em;
  font-weight: 400;
  letter-spacing: 0.1rem;
}

.cart-tabsno p {
  font-size: 1.3em;
  color: black;
  font-weight: 500;
}

.cart-detail-company p {
  margin: 0px;
  font-size: 1.05em;
}

.cart-quant-box {
  display: flex;
}

.cart-quantity {
  border: 1px solid rgb(226, 226, 226);
  padding: 10px 10px;
  width: 100px;
  text-align: center;
}

.cart-itm-price {
  float: right;
  color: #fff;
}

.cart-itm-price p {
  margin: 0px;
}

.cart-off-banner {
  width: 130px;
  position: relative;
  float: right;
  margin-right: 10px;
}

.cart-box-full {
  float: right;
  display: flex;
  width: 100%;
  margin-left: 25px;
}

.cart-quant-price {
  display: flex;
}

.cart-itm-price {
  width: 100%;
}

.cart-quant {
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-left: 70px;
}
.edit-cart {
  display: flex;
  justify-content: right;
  width: 100%;
  text-align: right;
  border-bottom: 1px solid rgb(194, 194, 194);
  padding-bottom: 20px;
  color: #fff;
}
.cart-edit {
  padding: 5px;
  border: 1px solid #c69736;
  float: right;
}
.cart-edit svg {
  font-size: 1.2em;
}
.edit-cart div:first-child {
  margin-left: 90%;
}
.shoppingbag-update button {
  float: right;
  padding: 10px 20px;
  background: #c69736;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  border: 2px solid #c69736;
}
.shoppingbag-update {
  width: 100%;
  height: 20px;
  padding: 20px 0px;
}

.increment {
  border: 1px solid #c69736;
  border-radius: 50%;
  font-size: 11px;
  padding-left: 3px;
  padding-right: 3px;
  position: relative;
  left: 1.4rem;
  color: #c69736;
}

.decrement {
  border: 1px solid #c69736;
  border-radius: 50%;
  font-size: 11px;
  padding: 3px;
  padding-right: 4px;
  padding-left: 4px;
  position: relative;
  right: 1.4rem;
  color: #c69736;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.subtotal-price {
  display: flex;
  position: relative;
  right: 3rem;
}

.reset-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pass-change-form-ctr2 {
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 50%;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    min-height: 25vh;
    margin-top: 10px;
    margin-bottom: 10px;
}

.reset-pass-form {
    width: 60%;
    margin-bottom: 15px;
}

.reset-pass-form-input {
    width: 100%;
    height: 5vh;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.reset-form-submit-btn {padding: 1rem;
    margin: 1rem !important;
    margin-left: 0 !important;
    color: #000 !important;
    background-color: #c69736 !important;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    border: 0;
    width: 100%;
    font-size: 15px;
    border-radius: 10px;
}

.reset-form-submit-btn:hover {
    padding: 1rem;
    margin: 1rem !important;
    margin-left: 0 !important;
    color: #000 !important;
    background-color: #9e782b !important;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    border: 0;
    width: 100%;
    font-size: 15px;
    cursor: pointer;
    border-radius: 10px;
}
.cancel_ctr {
    margin: 10px 0px 20px 0px;
    display: flex;
    padding: 15px;
    justify-content: center;
}

.oopwa-ctr {
    margin: 10px 0px 10px 0px;
    display: flex;
    padding: 15px;
    justify-content: center;
}

.cancel-btn-oopwa {
    border-radius: 5px;
}

.failedCtr {
    color: white;
    min-height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.failedBtn {
    text-decoration: none;
    cursor: pointer;
    font-size: 15px;
    color: #b28e35;
}

.failedBtn:hover {
    color: antiquewhite;
    text-decoration: underline;
    cursor: pointer;
    font-size: 15.5px;
}
.reset-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pass-change-form-ctr {
    display: flex;
    flex-direction: column;
    border: 1px solid #c69736;
    padding: 15px;
    width: 50%;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    min-height: 25vh;
    margin-top: 10px;
    margin-bottom: 10px;
}

.reset-pass-form {
    width: 60%;
    margin-bottom: 15px;
}

.reset-pass-form-input {
    width: 100%;
    height: 5vh;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.reset-form-submit-btn {padding: 1rem;
    margin: 1rem !important;
    margin-left: 0 !important;
    color: #000 !important;
    background-color: #c69736 !important;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    border: 0;
    width: 100%;
    font-size: 15px;
    border-radius: 10px;
}

.reset-form-submit-btn:hover {
    padding: 1rem;
    margin: 1rem !important;
    margin-left: 0 !important;
    color: #000 !important;
    background-color: #9e782b !important;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    border: 0;
    width: 100%;
    font-size: 15px;
    cursor: pointer;
    border-radius: 10px;
}
